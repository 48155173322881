import React from 'react';
import moment from 'moment';
import {
  Stack,
  Box,
  Heading,
  Text,
  Card,
  CardHeader,
  CardBody,
  StackDivider,
} from '@chakra-ui/react';

import { UseUserContext } from "../context/UserContext";

interface ChildProps {
  onAdvance?: () => void,
  onRetreat?: () => void,
}

const PhysicalProfile: React.FC<ChildProps> = (props) => {

  const { currentUser } = UseUserContext();

  // const [isLoading, setIsLoading] = useState(false);

  const startingWeight = Number(currentUser.data.last_weight_lbs);
  const targetWeight = Number(currentUser.data.goal_weight_lbs);
  // const weightDiff = startingWeight - targetWeight;
  // const calsDelta = weightDiff * 3500;
  // const goalTimeline = currentUser.data.goal_timeline;
  // const goalTimelineDays = goalTimeline === GoalTimelines.M12 ? 365 : goalTimeline === GoalTimelines.M3 ? 90 : 30;
  // const calsDeltaDays = calsDelta / goalTimelineDays;

  const birthMoment = moment(currentUser.data.birthday);
  const birthYearsAgo = moment().diff(birthMoment, 'years', false);
  const birthDaysAgo = moment().diff(birthMoment.add(birthYearsAgo, 'years'), 'days', false);

  const inchToFootString = (inches: number) => {
    return `${Math.floor(inches/12)}' ${Math.round(inches - 12 * Math.floor(inches/12))}"`;
  }

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <Heading size='md'>Your Basal Physical Profile</Heading>
        </CardHeader>

        <CardBody>
          <Stack divider={<StackDivider />} spacing='4'>
            <Box>
              <Heading size='xs' textTransform='uppercase'>
                Summary
              </Heading>
              <Text pt='2' fontSize='sm'>
                Your physical attributes profile below will be used to form the basis of your daily Basal Max Calories and Metabolic Weight Loss Plan. You can update this information at any time.
              </Text>
            </Box>
            <Box>
              <Heading size='xs' textTransform='uppercase'>
                PHYSICAL SEX
              </Heading>
              <Text pt='2' fontSize='sm'>
                {currentUser.data.gender?.toLocaleUpperCase()}
              </Text>
            </Box>
            <Box>
              <Heading size='xs' textTransform='uppercase'>
                HEIGHT
              </Heading>
              <Text pt='2' fontSize='sm'>
                {inchToFootString(Number(currentUser.data.height_in))}
              </Text>
            </Box>
            <Box>
              <Heading size='xs' textTransform='uppercase'>
                CURRENT AGE
              </Heading>
              <Text pt='2' fontSize='sm'>
                {birthYearsAgo} years and {birthDaysAgo} days
              </Text>
            </Box>
            <Box>
              <Heading size='xs' textTransform='uppercase'>
                PHYSICAL ACTIVITY
              </Heading>
              <Text pt='2' fontSize='sm'>
                Variable, updated based on daily progress
              </Text>
            </Box>
            <Box>
              <Heading size='xs' textTransform='uppercase'>
                LATEST WEIGHT
              </Heading>
              <Text pt='2' fontSize='sm'>
                {startingWeight} lbs
              </Text>
            </Box>
            <Box>
              <Heading size='xs' textTransform='uppercase'>
                INITIAL WEIGHT TARGET
              </Heading>
              <Text pt='2' fontSize='sm'>
                {targetWeight} lbs
              </Text>
            </Box>
          </Stack>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}

export default PhysicalProfile;