import {
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react';

function WeightInput(value: string, onSetValue: Function, db_field: string, placeholder: string) {
  const parse = (v: any) => {
    const newVal = v.replace(/^\$/, '');
    onSetValue(newVal, db_field);
  };

  return (
    <>
      {/* <FormControl 
       isInvalid={isError}
      > */}
      {/* {label && <FormLabel>{label}</FormLabel>} */}
      <NumberInput
        placeholder={placeholder}
        _placeholder={{ color: 'gray.500' }}
        value={value}
        onChange={valueString => parse(valueString)}>
        <NumberInputField />
      </NumberInput>
      {/* { subtitle && <FormHelperText>{subtitle}</FormHelperText>} */}
      {/* {!isError ? (
        <FormHelperText>
          Enter the email you'd like to receive the newsletter on.
        </FormHelperText>
      ) : (
        <FormErrorMessage>Email is required.</FormErrorMessage>
      )} */}
    {/* </FormControl> */}
    </>
  );
}

export default WeightInput;