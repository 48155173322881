// Example from https://react-chartjs-2.js.org/examples/
// https://react-chartjs-2.js.org/examples/line-chart

// import { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

// import { GlobalHeightData, getHeightIndex } from './HeightPredictor';
import { BirthData, getBirthdayIndex } from './BirthStats';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

// const heightMin = 4*12+8-1;
// const heightMax = 6*12+8+1;

export const options = {
  events: [],
  animation: {
    duration: 500
  },
  responsive: true,
  aspectRatio: 1,
  plugins: {
    filler: { propagate: false },
    legend: {
      // position: 'top' as const,
      display: false,
    },
    title: {
      display: false,
      // text: 'Measured Weight Log History',
    },
  },
  scales: {
    x: {
      // min: heightMin,
      // max: heightMax,
      display: false,
    },
    y: { display: false },
  },
  layout: {
    padding: 10
  }
};

export default function BirthdayChart(userBirthday: Date) {

  let birthdayData = BirthData;
  const userBirthdayIndex = getBirthdayIndex(userBirthday);

  let userBdayPoints = birthdayData.day_of_year.map(n => 0);
  const userBdayValue = birthdayData.probability[userBirthdayIndex];

  userBdayPoints[userBirthdayIndex] = userBdayValue;

  const transformChartData = (userBirthday: Date) => {
    const chartData = {
      labels: birthdayData.day_of_year,
      datasets: [
         {
          label: "Your Height",
          data: userBdayPoints,
          backgroundColor: "#38A169",
          borderWidth: 0,
          pointBorderColor: "#FFF",
          pointBorderWidth: 1,
          pointRadius: userBdayPoints.map(d => d > 0 ? 5 : 0),
          pointStyle: "circle",
          fill: {
            target: 'origin',
            above: "#9AE6B4",   // Area will be red above the origin
            below: "#9AE6B4",    // And blue below the origin
          }
        },
        {
          label: "Global Birthday Distribution",
          data: birthdayData.probability,
          pointBorderWidth: 0,
          pointRadius: 0,
          borderWidth: 2,
          borderColor: "#38A169",
          fill: {
            target: 'origin',
            above: "#EDF2F7",   // Area will be red above the origin
            below: "#EDF2F7",    // And blue below the origin
          }
        },
      ]
    }

    return chartData;
  }

  const transformedChartData = transformChartData(userBirthday);

  return <Line options={options} data={transformedChartData} />;
}