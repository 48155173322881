import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Button,
  Stack,
  Box,
  Divider,
  Heading,
  VStack,
  Text,
  Badge,
  Card,
  CardHeader,
  CardBody,
  StackDivider,
} from '@chakra-ui/react';
import { 
  collection, addDoc, onSnapshot,
} from 'firebase/firestore';

import { UseUserContext } from "../../context/UserContext";
import { logEvent } from 'firebase/analytics';
import { db, analytics } from '../../firebase/firebase';
import { calcBMI } from '../../bmr/bmr';
import LogoImage from '../../components/LogoImage';

interface ChildProps {
  onAdvance?: () => void,
  onRetreat?: () => void,
}

enum GoalTimelines {
  M12 = '12',
  M3 = '3',
  M1 = '1',
}

const OnboardingPlan: React.FC<ChildProps> = (props) => {

  const { currentUser, updateCurrentUserData } = UseUserContext();
  const [isLoading, setLoading] = useState(false);

  const startingWeight = Number(currentUser.data.last_weight_lbs);
  const targetWeight = Number(currentUser.data.goal_weight_lbs);
  const weightDiff = startingWeight - targetWeight;
  const calsDelta = weightDiff * 3500;
  const goalTimeline = currentUser.data.goal_timeline;
  const goalTimelineDays = goalTimeline === GoalTimelines.M12 ? 365 : goalTimeline === GoalTimelines.M3 ? 90 : 30;
  const calsDeltaDays = calsDelta / goalTimelineDays;
  const ppw = 7 * weightDiff / goalTimelineDays;
  const goalDeadline = moment().add(goalTimelineDays, 'days').format('YYYY-MM-DD');

  const chance_success = currentUser.data.goal_sim_odds;
  const successChanceLabel = chance_success >= 0.50 ? "High" : chance_success >= 0.25 ? "Good" : chance_success >= 0.15 ? "Avg" : "Low";
  const successChanceColor = chance_success >= 0.50 ? "green" :chance_success >= 0.25 ? "teal" : chance_success >= 0.15 ? "orange" : "red";

  const initialBMI = calcBMI(startingWeight, Number(currentUser.data.height_in));
  const targetBMI = calcBMI(targetWeight, Number(currentUser.data.height_in));

  const stripeCheckout = async () => {

    console.log("Called stripeCheckout");

    // Create checkout session document
    try {
      submitUpdateProfile();
      setLoading(true);
      const checkout_session_ref = collection(db, `users/${currentUser.auth.uid}/checkout_sessions`);
      const checkoutDocRef = await addDoc(checkout_session_ref, {
        price: 'price_1MIe4QFxMgcRye3wPKK0SQcq',
        success_url: window.location.href,
        cancel_url: window.location.href,
        trial_from_plan: false, // No support for trial_days unfortunately, so use legacy param
      });
      console.log("Created checkoutDocRef: ",checkoutDocRef);

      // Listen to checkout doc snapshot
      const unsub = onSnapshot(checkoutDocRef, (snap) => {
        console.log("Called onSnapshot with snap: ",snap);
        const data = snap.data();
        console.log("Snapshot has data: ",data);
        if (data) {
          console.log("Current data: ", data);
          const { error, url } = data;
          if (error) {
            // Show an error to your customer and
            // inspect your Cloud Function logs in the Firebase console.
            alert(`An error occured: ${error.message}`);
          }
          if (url) {
            // We have a Stripe Checkout URL, let's redirect.
            console.log("Redirecting to url: ",url);
            setLoading(false);
            logEvent(analytics, 'checkout_initiated', {
              uid: currentUser.auth.uid
            });
            window.location.assign(url);
          }
        } else {
          setLoading(false);
          // Something went wrong
          console.error("Did not expect this to be triggered");
        }
      });
    } catch (e) {
      console.error("Failed to create a checkout session: ",e);
      logEvent(analytics, 'checkout_init_failure', {
        uid: currentUser.auth.uid
      });
      setLoading(false);
    }

    // Create checkout session
    // const session = await createCheckoutSession(payments, {
    //   price: "price_1MIe4QFxMgcRye3wPKK0SQcq",
    //   success_url: window.location.origin, // "https://example.com/payments/success",
    //   cancel_url: window.location.origin, // "https://example.com/payments/cancel",
    //   trial_period_days: 7,
    // });
    // window.location.assign(session.url);

    // onCurrentUserSubscriptionUpdate(
    //   payments,
    //   (snapshot) => {
    //     for (const change in snapshot.changes) {
    //       if (change.type === "added") {
    //         console.log(`New subscription added with ID: ${change.subscription.id}`);
    //       }
    //     }
    //   }
    // );
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const submitUpdateProfile = async () => {
    if (true) {
      console.log("Submitting profile update...");
      const profileUpdate = {
        // goal_weight_lbs: targetWeight,
        goal_end_date: goalDeadline,
        // freeTrialStarted: new Date(),
      }
      setLoading(true);
      await updateCurrentUserData(currentUser.auth.uid!, profileUpdate);
      console.log("User profile updated...");
      logEvent(analytics, 'profile_updated', {
        uid: currentUser.auth.uid,
        onboard_stage: "onboarded_plan",
      });
      setLoading(false);
      if (props.onAdvance) props.onAdvance(); // Advance to next step
    }
  }

  return (
    <React.Fragment>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <VStack spacing={8} align="stretch">

          <LogoImage boxSize={"xs"}/>

          <Heading textAlign={"center"} fontSize={'4xl'}>Your Basal Metabolic Weight Loss Plan</Heading>

          <Text fontSize='xl' textAlign={"center"}>We will automatically adjust your plan as needed, and you can change this plan at any time by logging into your account.</Text>

          <Card>
            <CardHeader>
              <Heading size='md'>Your Weight Loss Plan</Heading>
            </CardHeader>

            <CardBody>
              <Stack divider={<StackDivider />} spacing='4'>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    Summary
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    Hitting your goal weight of {targetWeight} lbs in {goalTimelineDays} days will require you to burn a total of {calsDelta.toLocaleString()} Calories. 
                  </Text>
                </Box>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    WEIGHT LOSS TARGET
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    Targeted weight loss of {weightDiff.toLocaleString()} lbs in {goalTimelineDays} days
                  </Text>
                </Box>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    TARGET BMI ESTIMATE
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    Targeted reduction in BMI to {targetBMI.bmi}% from {initialBMI.bmi}%
                  </Text>
                </Box>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    STARTING CALORIE DEFICIT
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    Estimated average of ~{Math.ceil(calsDeltaDays).toLocaleString()} Calories per day for {goalTimelineDays} days. Adjustments will be made automatically based on your daily progress.
                  </Text>
                </Box>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    PHYSICAL ACTIVITY
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    Variable, updated based on daily progress
                  </Text>
                </Box>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    INITIAL Simulated Odds
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    <Badge colorScheme={successChanceColor}>{successChanceLabel}</Badge> estimated chance of success based on your physical attributes and timeline
                  </Text>
                </Box>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    WEIGHT LOSS VELOCITY
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    Estimated {ppw.toLocaleString()} lbs per week of weight loss, or {(ppw/7).toLocaleString()} lbs per day
                  </Text>
                </Box>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    BASAL MAX CALORIES
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    <Badge colorScheme={"orange"}>available after enrollment</Badge>
                  </Text>
                </Box>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    TARGET TIMELINE
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    Goal target deadline set to {goalDeadline}, meaning {goalTimelineDays} days to hit {targetWeight} lbs, or an adaptively set goal depending on progress
                  </Text>
                </Box>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    SUBSCRIPTION PLAN
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    {goalTimelineDays} day plan to support {goalTimelineDays} days of AI-powered daily estimations and logging
                  </Text>
                </Box>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    NOTIFICATION METHOD
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    You will need to log your weight each morning via text message. <br/><br/>Basal.ai will send you a text message with your updated stats each morning, including your new Basal Max and Basal True Weight. <br/><br/>You can also check your stats at any time by logging into your account.
                  </Text>
                </Box>
              </Stack>
            </CardBody>
          </Card>

          <Divider hidden />

          <Stack spacing={6} direction={['column', 'row']}>
            {/* <Button
              bg={'red.400'}
              color={'white'}
              w="full"
              _hover={{
                bg: 'red.500',
              }}>
              Cancel
            </Button> */}

              <Button
                bg={'green.400'}
                color={'white'}
                w="full"
                // disabled={!isValidTargetWeight}
                isLoading={isLoading}
                onClick={submitUpdateProfile}
                _hover={{
                  bg: 'green.500',
                }}>
                Let's Get Started!
              </Button>
            
          </Stack>

        </VStack>
      </Stack>
    </React.Fragment>
  );
}

export default OnboardingPlan;