import React, { useEffect, useState } from 'react';
import {
  Button,
  Stack,
  Heading,
  VStack,
  Text,
  Link,
} from '@chakra-ui/react';

import { UseUserContext } from "../../context/UserContext";
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebase/firebase';

import LogoImage from '../../components/LogoImage';

interface ChildProps {
  onAdvance: () => void,
  onRetreat: () => void,
}

const OnboardingStart: React.FC<ChildProps> = (props) => {

  const { currentUser, updateCurrentUserData } = UseUserContext();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const submitUpdateProfile = async () => {
    if (true) {
      // console.log("Submitting profile update...");
      // const profileUpdate = {
      //   birthday: birthMoment.format("YYYY-MM-DD"),
      // }
      // setIsLoading(true);
      // await updateCurrentUserData(currentUser.auth.uid!, profileUpdate);
      // console.log("User profile updated...");
      logEvent(analytics, 'profile_updated', {
        uid: currentUser.auth.uid,
        onboard_stage: "started_onboarding",
      });
      setIsLoading(false);
      props.onAdvance(); // Advance to next step
    }
  }

  return (
    <React.Fragment>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <VStack spacing={8} align="stretch">
          <LogoImage boxSize="xs"/>
          <Heading textAlign={"center"} fontSize={'5xl'}>Let's get you set up!</Heading>
          <Text fontSize={'2xl'} color={'gray.600'}>
            Heads up! We will need some information to calculate your <Link color={'green.400'} href="https://en.wikipedia.org/wiki/Basal_metabolic_rate" target={"_blank"}>Basal Metabolic Rate</Link> (BMR), and to benchmark your progress each day ✌️
          </Text>
          <Text fontSize={'xl'} color={'gray.600'}>
            This also will allow us to dynamically update your <strong>Basal AI True Weight</strong> estimation with the greatest accuracy possible, so double check those numbers!
          </Text>
          <Button
            size={"lg"}
            bg={'green.400'}
            color={'white'}
            width="full"
            onClick={submitUpdateProfile}
            _hover={{ bg: 'green.500' }}>
            Gotcha
          </Button>
        </VStack>
      </Stack>
    </React.Fragment>
  );
}

export default OnboardingStart;