import React, { useEffect, useState } from 'react';
import {
  Button,
  Stack,
  Divider,
  Heading,
  VStack,
  Text,
  FormControl,
  FormLabel,
  Checkbox,
} from '@chakra-ui/react';
import PhoneInput from 'react-phone-number-input';
import { Value as E164Number } from 'react-phone-number-input';

import { UseUserContext } from "../../context/UserContext";
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebase/firebase';
import PhysicalProfile from '../../components/PhysicalProfile';

interface ChildProps {
  onAdvance: () => void,
  onRetreat: () => void,
}

const OnboardingPhone: React.FC<ChildProps> = (props) => {

  const { currentUser, updateCurrentUserData } = UseUserContext();
  const [isLoading, setIsLoading] = useState(false);
  const [userPhoneNumber, setUserPhoneNumber] = useState(String(currentUser.data.phoneNumber || ""));

  const [smsChecked, setSmsCheck] = useState(false);

  const setPhoneNumber = (num?: E164Number | undefined) => {
    if (!num) return;
    setUserPhoneNumber(num);
  }

  const isValidPhoneNumber = userPhoneNumber.length > 0;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const submitUpdateProfile = async () => {
    if (isValidPhoneNumber) {
      console.log("Submitting profile update...");
      const profileUpdate = {
        phoneNumber: userPhoneNumber,
      }
      setIsLoading(true);
      await updateCurrentUserData(currentUser.auth.uid!, profileUpdate);
      console.log("User profile updated...");
      logEvent(analytics, 'profile_updated', {
        uid: currentUser.auth.uid,
        onboard_stage: "onboarded_phone",
      });
      setIsLoading(false);
      props.onAdvance(); // Advance to next step
    }
  }

  return (
    <React.Fragment>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <VStack spacing={8} align="stretch">

        <Heading textAlign={"center"} fontSize={'4xl'}>Time to Generate Your Weight Loss Plan</Heading>
          <Heading textAlign={"center"} fontSize={'3xl'}>What's your phone number?</Heading>
          <Heading textAlign={"center"} fontSize={'xl'} color="green.500">Generate Your Weight Loss Plan for Free</Heading>
          <Text fontSize='2xl' textAlign={"center"}>Basal.ai works best with your cell phone, so we're gonna need your number ✌️</Text>

          <FormControl id="phone">
            <FormLabel>Enter your phone number</FormLabel>
            <PhoneInput
              style={{ 
                borderRadius: "10px",
                border: "1px solid #e2e8f0",
                padding: "10px",
               }}
              // isinvalid={!validPhoneNumber}
              defaultCountry="US"
              placeholder="Phone number"
              _placeholder={{ color: 'gray.500' }}
              type="tel"
              onChange={setPhoneNumber} 
              value={userPhoneNumber}
              // isReadOnly
              // disabled
            />
          </FormControl>

          <Checkbox
            isChecked={smsChecked}
            onChange={(e) => setSmsCheck(!smsChecked)}
          >I agree to receive SMS and MMS messages from Basal.ai and I understand that carrier rates and fees may apply</Checkbox>

          {/* <Text fontSize='md' textAlign={"center"}>Make sure to enter a phone number you can send and receive text messages from</Text> */}

          <Divider hidden />

          <Stack spacing={6} direction={['column', 'row']}>
            {/* <Button
              bg={'red.400'}
              color={'white'}
              w="full"
              _hover={{
                bg: 'red.500',
              }}>
              Cancel
            </Button> */}

            {(!!userPhoneNumber && !!smsChecked) && (
              <Button
                bg={'green.400'}
                color={'white'}
                w="full"
                isDisabled={!isValidPhoneNumber}
                isLoading={isLoading}
                onClick={submitUpdateProfile}
                _hover={{
                  bg: 'green.500',
                }}>
                Looks good! Generate my plan.
              </Button>
            )}
            
          </Stack>

          <Divider />

          {/* <Heading textAlign={"center"} fontSize={'5xl'}>Confirm Your Basal Profile Attributes</Heading> */}

          <Text fontSize='xl'>We will calculate a customized adaptive Basal weight loss plan based on your physical attributes below.</Text>

          <Text fontSize='xl'>Please double-check the information below:</Text>

          <PhysicalProfile />

        </VStack>
      </Stack>
    </React.Fragment>
  );
}

export default OnboardingPhone;