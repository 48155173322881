import {
  Input,
  Text,
} from '@chakra-ui/react';

function DateInput(value: string, onSetValue: Function, db_field: string | null, placeholder: string) {
  const parse = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVal = String(e.target.value) || "";
    onSetValue(newVal, db_field);
  };

  return (
    <>
      {/* <FormControl 
       isInvalid={isError}
      > */}
      {/* {label && <FormLabel>{label}</FormLabel>} */}
      { !value  && <Text mb='8px' color={"red"}>Please enter a date in mm/dd/yyyy format </Text> }
      <Input
        placeholder={placeholder}
        _placeholder={{ color: 'gray.500' }}
        value={value}
        type="date"
        onChange={e => parse(e)}>
        {/* <InputField /> */}
      </Input>

      {/* { subtitle && <FormHelperText>{subtitle}</FormHelperText>} */}
      {/* {!isError ? (
        <FormHelperText>
          Enter the email you'd like to receive the newsletter on.
        </FormHelperText>
      ) : (
        <FormErrorMessage>Email is required.</FormErrorMessage>
      )} */}
    {/* </FormControl> */}
    </>
  );
}

export default DateInput;