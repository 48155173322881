// Example from https://react-chartjs-2.js.org/examples/
// https://react-chartjs-2.js.org/examples/line-chart

// import { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import { GlobalHeightData, getHeightIndex } from './HeightPredictor';

enum Sexes {
  UNKNOWN = 'human',
  MALE = 'male',
  FEMALE = 'female',
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

const heightMin = 4*12+8-1;
const heightMax = 6*12+8+1;

export const options = {
  events: [],
  animation: {
    duration: 500
  },
  responsive: true,
  aspectRatio: 1,
  plugins: {
    filler: { propagate: false },
    legend: {
      // position: 'top' as const,
      display: false,
    },
    title: {
      display: false,
      // text: 'Measured Weight Log History',
    },
  },
  scales: {
    x: {
      min: heightMin,
      max: heightMax,
      display: false,
    },
    y: { display: false },
  },
  layout: {
    padding: 10
  }
};

export default function SexHeightChart(userHeight: Number, userSex: Sexes) {

  let heightData = GlobalHeightData;
  const userHeightIndex = getHeightIndex(userHeight);

  let userHeightPoints = heightData.heights_in.map(n => 0);
  let maleUserHeightPoints = heightData.heights_in.map(n => 0);
  let femaleUserHeightPoints = heightData.heights_in.map(n => 0);
  
  const userHeightValue = heightData.probmass_human[userHeightIndex];
  const maleUserHeightValue = heightData.probmass_male[userHeightIndex];
  const femaleUserHeightValue = heightData.probmass_female[userHeightIndex];

  userHeightPoints[userHeightIndex] = userHeightValue;
  maleUserHeightPoints[userHeightIndex] = maleUserHeightValue;
  femaleUserHeightPoints[userHeightIndex] = femaleUserHeightValue;

  const transformChartData = (userHeight: Number) => {
    const chartData = {
      labels: heightData.heights_in,
      datasets: [
        //  {
        //   label: "Your Height",
        //   data: userHeightPoints,
        //   backgroundColor: "#38A169",
        //   borderWidth: 0,
        //   pointBorderColor: "#FFF",
        //   pointBorderWidth: 1,
        //   pointRadius: userHeightPoints.map(d => d > 0 ? 5 : 0),
        //   pointStyle: "circle",
        //   fill: {
        //     target: 'origin',
        //     above: "#9AE6B4",   // Area will be red above the origin
        //     below: "#9AE6B4",    // And blue below the origin
        //   }
        // },
        // {
        //   label: "Global Height Distribution",
        //   data: heightData.probmass_human,
        //   pointBorderWidth: 0,
        //   pointRadius: 0,
        //   borderWidth: 2,
        //   borderColor: "#38A169",
        //   fill: {
        //     target: 'origin',
        //     above: "#EDF2F7",   // Area will be red above the origin
        //     below: "#EDF2F7",    // And blue below the origin
        //   }
        // },
        {
          label: "Male Height Distribution",
          data: heightData.probmass_male,

          backgroundColor: userSex === "male" ? "#0BC5EA" : "#bcbcbc",
          pointBorderColor: "#FFF",
          pointBorderWidth: 1,
          pointRadius: maleUserHeightPoints.map(d => d > 0 && userSex === "male" ? 5 : 0),
          pointStyle: "circle",
          
          borderWidth: 2,
          borderColor: userSex === "male" ? "#0BC5EA" : "#bcbcbc",
          // fill: {
          //   target: 'origin',
          //   above: "#C4F1F9",   // Area will be red above the origin
          //   below: "#C4F1F9",    // And blue below the origin
          // }
        },
        {
          label: "Female Height Distribution",
          data: heightData.probmass_female,
          
          backgroundColor: userSex === "female" ? "#9F7AEA" : "#bcbcbc",
          pointBorderColor: "#FFF",
          pointBorderWidth: 1,
          pointRadius: femaleUserHeightPoints.map(d => d > 0 && userSex === "female" ? 5 : 0),
          pointStyle: "circle",

          borderWidth: 2,
          borderColor: userSex === "female" ? "#9F7AEA" : "#bcbcbc",
          // fill: {
          //   target: 'origin',
          //   above: "#E9D8FD",   // Area will be red above the origin
          //   below: "#E9D8FD",    // And blue below the origin
          // }
        },
      ]
    }

    return chartData;
  }

  const transformedChartData = transformChartData(userHeight);

  return <Line options={options} data={transformedChartData} />;
}