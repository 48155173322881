import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Button,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  Heading,
  VStack,
  Center,
  Input,
  Text,
} from '@chakra-ui/react';

import { UseUserContext } from "../../context/UserContext";
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebase/firebase';
import { birthdayProb, birthdayCDF } from '../../components/BirthStats';
import BirthdayChart from '../../components/BirthdayChart';

interface ChildProps {
  onAdvance: () => void,
  onRetreat: () => void,
}

const OnboardingSex: React.FC<ChildProps> = (props) => {

  const { currentUser, updateCurrentUserData } = UseUserContext();
  
  // From previous sessions
  const globalPopulation = 8000000000; // 8 billion as of end of 2022

  const dateInputFormat = "YYYY-MM-DD";
  const [birthdayPickerValue, setBirthdayPickerValue] = useState("");
  const userBirthday = birthdayPickerValue ? moment(birthdayPickerValue).toDate() : "";

  const birthMoment = moment(birthdayPickerValue, "YYYY-MM-DD");
  const birthYearsAgo = moment().diff(birthMoment, 'years', false);
  const birthDaysAgo = moment().diff(birthMoment.add(birthYearsAgo, 'years'), 'days', false);

  const isValidAge = !!birthYearsAgo && birthYearsAgo >= 18 && birthYearsAgo <= 117;

  const birthdayChance = userBirthday ? birthdayProb(userBirthday) : 0;
  const birthdayCumChance = userBirthday ? birthdayCDF(userBirthday) : 0;

  // Calculate BMR impact on age
  const bmrAgeImpact = currentUser.data.gender === 'male' ? 5.677 : 4.33;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const submitUpdateProfile = async () => {
    if (!!birthYearsAgo) {
      console.log("Submitting profile update...");
      const profileUpdate = {
        birthday: moment(birthdayPickerValue, "YYYY-MM-DD").format("YYYY-MM-DD"),
      }
      setIsLoading(true);
      await updateCurrentUserData(currentUser.auth.uid!, profileUpdate);
      console.log("User profile updated...");
      logEvent(analytics, 'profile_updated', {
        uid: currentUser.auth.uid,
        onboard_stage: "onboarded_birthday",
      });
      setIsLoading(false);
      props.onAdvance(); // Advance to next step
    }
  }

  return (
    <React.Fragment>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <VStack spacing={8} align="stretch">

          <Heading textAlign={"center"} fontSize={'6xl'}>What's your birthday?</Heading>
          <Text fontSize='2xl' textAlign={"center"}>Your body metabolizes differently as you age</Text>

          <Heading textAlign={"center"} fontSize={'2xl'}>{isValidAge ? `Born on ${birthMoment.format("MMMM Do, YYYY")}` : "Enter your birthday"}</Heading>

          <Center>
            <Input
              placeholder={"Enter your birthday mm/dd/yyyy"}
              _placeholder={{ color: 'gray.500' }}
              value={birthdayPickerValue}
              type="date"
              onChange={e => {
                e.preventDefault();
                if (e.target.value) setBirthdayPickerValue(e.target.value);
              }}
              >
            </Input>
          </Center>

          { isValidAge && (
            <>
              { BirthdayChart(moment(birthdayPickerValue).toDate()) }
              <Stat>
                <StatLabel>You share a birthday with ~{Math.floor(globalPopulation*birthdayChance).toLocaleString()} people across the world</StatLabel>
                <StatNumber>You were born {birthYearsAgo} years and {birthDaysAgo} days ago</StatNumber>
                <StatHelpText>
                  <StatArrow color={"green.500"} type={birthdayCumChance <= 0.5 ? "increase" : "decrease"} />
                  That's one of the top {(100*(birthdayCumChance <= 0.5 ? birthdayCumChance : 1 - birthdayCumChance)).toFixed(4)}% {birthdayCumChance <= 0.5 ? "most popular" : "rarest"} days of the year -- you can celebrate your next birthday with {(100*birthdayChance).toFixed(4)}% of the planet.
                </StatHelpText>
              </Stat>
            </>
          )}

          <p>We update your BMR equation every single day because MR will decline by approximately {bmrAgeImpact.toLocaleString()} Calories per year, or {(bmrAgeImpact/365).toLocaleString()} Calories per day on average as you age.</p>
        
          <Stack spacing={6} direction={['column', 'row']}>
            {/* <Button
              bg={'red.400'}
              color={'white'}
              w="full"
              _hover={{
                bg: 'red.500',
              }}>
              Cancel
            </Button> */}

            {isValidAge && (
              <Button
                bg={'green.400'}
                color={'white'}
                w="full"
                isDisabled={!isValidAge || isLoading}
                isLoading={isLoading}
                onClick={submitUpdateProfile}
                _hover={{
                  bg: 'green.500',
                }}
                >
                I am {birthYearsAgo} years and {birthDaysAgo} days young
              </Button>
            )} 
            
          </Stack>

          {/* <p><i>Modern medicine is changing the equation. In 2013, births were over 50% more likely to happen on a weekday than a weekend, because hospitals are more fully staffed at those times. Expecting parents also try to avoid leap days.</i></p> */}

        </VStack>
      </Stack>
    </React.Fragment>
  );
}

export default OnboardingSex;