// import Head from 'next/head';
import {
  Box,
  Heading,
  Container,
  Text,
  Button,
  Stack,
  Icon,
  useColorModeValue,
  createIcon,
  Image,
  Center,
  StackDivider,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import LogoImage from '../components/LogoImage';

import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebase/firebase';

const logCTAButtonClick = () => {
  // console.log("Logging logCTAButtonClick");
  logEvent(analytics, 'cta_click', {
    name: 'landing_page_cta'
  });  
}

export default function LandingPage() {
  return (
    <>

      <Container maxW={'3xl'}>
        <Stack
          as={Box}
          textAlign={'center'}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}>

          <Heading
            fontWeight={100}
            fontSize={{ base: '6xl', sm: '6xl', md: '6xl' }}
            lineHeight={'110%'}>
            <Text as={'span'} color={'black'} fontFamily="monospace">
              Basal AI
            </Text>
          </Heading>

          <LogoImage />
          
          <Heading
            fontWeight={600}
            fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
            lineHeight={'110%'}>
            Lose weight with <br />
            <Text as={'span'} color={'green.400'}>
              with the power of math
            </Text>
          </Heading>
          <Heading
            fontWeight={400}
            fontSize={{ base: 'xl', sm: '2xl', md: '3xl' }}
            lineHeight={'110%'}>
            AI-powered metabolic weight loss strategy
          </Heading>
          <Text color={'gray.500'}>
            No fad diets. No off-limit foods. No exercise required.
            <br/>
            If you stick with it, it just works. It's so simple.
            <br/>
            It's just a matter of math.
          </Text>
          <Stack
            direction={'column'}
            spacing={3}
            align={'center'}
            alignSelf={'center'}
            position={'relative'}>
            <Link to="/auth">
              <Button
                colorScheme={'green'}
                bg={'green.400'}
                rounded={'full'}
                px={6}
                onClick={logCTAButtonClick}
                _hover={{
                  bg: 'green.500',
                }}>
                Get Started
              </Button>
            </Link>
            {/* <Button variant={'link'} colorScheme={'blue'} size={'sm'}>
              Learn more
            </Button> */}
            <Box>
              <Icon
                as={Arrow}
                color={useColorModeValue('gray.800', 'gray.300')}
                w={71}
                position={'absolute'}
                right={-71}
                top={'10px'}
              />
              <Text
                fontSize={'lg'}
                fontFamily={'Caveat'}
                position={'absolute'}
                right={'-125px'}
                top={'-15px'}
                transform={'rotate(10deg)'}>
                Try it for free!
              </Text>
            </Box>
          </Stack>

          <StackDivider />

          <Heading 
            textAlign={"left"} 
            fontWeight={400}
            fontSize={{ base: '1xl', sm: '2xl', md: '3xl' }}
            marginBottom="0"
          >
            Yes, it really is <strong>this simple</strong> to lose weight.
          </Heading>
          <Box alignContent={"left"}>
            <Image src='/assets/images/Basal-CalorieMax.png'/>
          </Box>

          <Center>
            <Box boxSize="md">
              <Image src='/assets/images/Basal-SMS.jpg'/>
            </Box>
          </Center>
          
          <Heading 
            textAlign={"left"} 
            fontWeight={400}
            fontSize={{ base: '1xl', sm: '2xl', md: '3xl' }}
            marginBottom="0"
          >
             <strong>Basal True Weight</strong> automatically tunes to your body's cycles and adjusts your Basal Calorie Max accordingly
          </Heading>
          <Box alignContent={"left"}>
            <Image src='/assets/images/Basal-TrueWeight.png'/>
          </Box>
          <Center>
          <Image src='/assets/images/Basal-Example-TrueWeight.png'/>
          </Center>

          <Heading 
            textAlign={"left"} 
            fontWeight={400}
            fontSize={{ base: '1xl', sm: '2xl', md: '3xl' }}
            marginBottom="0"
          >
            <strong>Plan for success</strong> with metabolic simulations and odds predictions based on thousands of data points
          </Heading>
          <Center>
            <Link to="/auth">
              <Image src='/assets/images/OddsSimulation.png' />
            </Link>
          </Center>

          <Heading 
            textAlign={"center"} 
            fontWeight={400}
            fontSize={{ base: '1xl', sm: '2xl', md: '3xl' }}
            marginBottom="0"
          >
            Well, what are you waiting for?
          </Heading>
          {/* <Text>
            Want to learn more about the <strong><a href="https://en.wikipedia.org/wiki/Basal_metabolic_rate" rel="noopener noreferrer" target="_blank">Basal Metabolic Rate (BMR)?</a></strong> 
            <br /><br />
            In short, your body generates heat, and we can estimate your BMR with 60-75% accuracy using simple math. However, Basal AI updates your BMR calculation each day, which will automatically adjust our model for your cycles, physical activity, and other metabolic variables unique to you.
            <br /><br />
            This will help you better hit your weight targets, whether that be to lose weight, gain weight, or maintain a certain weight. In fact, we use it ourselves every single day.
            <br /><br />
            Pretty cool, huh? We think so, too!
          </Text> */}
          <Link to="/auth">
            <Button
              colorScheme={'green'}
              bg={'green.400'}
              rounded={'full'}
              size="lg"
              px={6}
              onClick={logCTAButtonClick}
              _hover={{
                bg: 'green.500',
              }}>
              Alright, alright, alright. Let's go!
            </Button>
          </Link>
          {/* <Text><i>No, we aren't sponsored by Matthew McConaughey. Yet.</i></Text> */}
          {/* <i>Also, please consult your nutritionist and healthcare provider when using Basal AI</i> */}
          <Text>We're always available at <strong><a href="mailto:hello@basal.ai">hello@basal.ai</a></strong></Text>
        </Stack>
      </Container>
    </>
  );
}

const Arrow = createIcon({
  displayName: 'Arrow',
  viewBox: '0 0 72 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.600904 7.08166C0.764293 6.8879 1.01492 6.79004 1.26654 6.82177C2.83216 7.01918 5.20326 7.24581 7.54543 7.23964C9.92491 7.23338 12.1351 6.98464 13.4704 6.32142C13.84 6.13785 14.2885 6.28805 14.4722 6.65692C14.6559 7.02578 14.5052 7.47362 14.1356 7.6572C12.4625 8.48822 9.94063 8.72541 7.54852 8.7317C5.67514 8.73663 3.79547 8.5985 2.29921 8.44247C2.80955 9.59638 3.50943 10.6396 4.24665 11.7384C4.39435 11.9585 4.54354 12.1809 4.69301 12.4068C5.79543 14.0733 6.88128 15.8995 7.1179 18.2636C7.15893 18.6735 6.85928 19.0393 6.4486 19.0805C6.03792 19.1217 5.67174 18.8227 5.6307 18.4128C5.43271 16.4346 4.52957 14.868 3.4457 13.2296C3.3058 13.0181 3.16221 12.8046 3.01684 12.5885C2.05899 11.1646 1.02372 9.62564 0.457909 7.78069C0.383671 7.53862 0.437515 7.27541 0.600904 7.08166ZM5.52039 10.2248C5.77662 9.90161 6.24663 9.84687 6.57018 10.1025C16.4834 17.9344 29.9158 22.4064 42.0781 21.4773C54.1988 20.5514 65.0339 14.2748 69.9746 0.584299C70.1145 0.196597 70.5427 -0.0046455 70.931 0.134813C71.3193 0.274276 71.5206 0.70162 71.3807 1.08932C66.2105 15.4159 54.8056 22.0014 42.1913 22.965C29.6185 23.9254 15.8207 19.3142 5.64226 11.2727C5.31871 11.0171 5.26415 10.5479 5.52039 10.2248Z"
      fill="currentColor"
    />
  ),
});