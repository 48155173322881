import { useContext, createContext, useState } from 'react';

import User from "../types/User";
import UserAuth from '../types/UserAuth';
import UserData from "../types/UserData";

import { getUser, getUserWeightLog, logUserWeight, updateUser } from "../firebase/firebase";
import WeightLog from '../types/WeightLog';

interface IUserContext {
  currentUser: User;
  setCurrentUser: (userAuthData: UserAuth) => Promise<null>;
  setTempUserData: (userData: UserData) => Promise<null>; 
  tempUserData: UserData | null;
  updateCurrentUserData: (uid: string, newUserData: UserData, measuredWeightDate?: string) => Promise<null>;
}

const defaultUserState = {
  currentUser: { auth: {}, data: {} },
  setCurrentUser: async () => null,
  setTempUserData: async () => null,
  tempUserData: null,
  updateCurrentUserData: async () => null,
};

const UserContext = createContext<IUserContext>(defaultUserState);

// Hook to provide access to context object
// https://felixgerschau.com/react-typescript-context/
export const UseUserContext = () => useContext(UserContext);

// Example from: https://contactmentor.com/react-context-with-hooks/

// const ExampleUserData: UserData[] = [
//   {
//     id: "EFVr1hTSzdY1qBTZemisKHAOagX2",
//     name: "Deanna",
//     gender: "female",
//     height_in: 61.5,
//     birthday: "1993-07-08",
//     last_weight_lbs: 125,
//     goal_weight_lbs: 120,
//     goal_end_date: "2022-09-22",
//   },
//   {
//     id: "Jll9gLRAvSZkn4KpXIVeW7zRjz02",
//     name: "Dakota",
//     gender: "male",
//     height_in: 74.0157,
//     birthday: "1994-09-10",
//     last_weight_lbs: 164,
//     goal_weight_lbs: 160,
//     goal_end_date: "2022-09-22",
//   },
// ];

export const UserContextProvider = (props: any) => {
  // const [apiData, setApiData] = useState([]);
  // const globalValue = "Global Value"

  const [currentUserObj, setCurrentUserObj] = useState<User>(defaultUserState.currentUser);
  const [tempUserDataObj, setTempUserDataObj] = useState<UserData | null>(null);

  const setCurrentUser = async (userAuthData: UserAuth) => {

    let userData: UserData;
    let userWeightLog: WeightLog;

    try {
      userData = await getUser(userAuthData.uid);
      userWeightLog = await getUserWeightLog(userAuthData.uid);
    } catch (e) {
      console.error("Failed to retrieve current user data fields");
      return null;
    }
    
    // Update user object with auth data
    const currentUserData = { 
      auth: userAuthData,
      data: userData,
      weight_log: userWeightLog,
    };
    // console.log("Setting currentUserData: ",currentUserData);
    setCurrentUserObj(currentUserData);

    // Also update temp user data
    setTempUserDataObj(null);

    return null;
  };

  const setTempUserData = async (newUserData: UserData) => {

    // Update user object with new data where available
    const tempObj = {
      ...currentUserObj.data,
      ...tempUserDataObj,
      ...newUserData
    };
    setTempUserDataObj(tempObj);
    return null;
  };

  const updateCurrentUserData = async (uid: string, newUserData: UserData, measuredWeightDate?: string) => {
    // Update user in database
    try {
      const newUserDoc = await updateUser(uid, newUserData);
      const measuredWeight = newUserData.last_weight_lbs;
      let weightLog: WeightLog | null = null;
      if (!!measuredWeight) {
        try {
          weightLog = await logUserWeight(uid, measuredWeight, measuredWeightDate);
        } catch (e) {
          console.error("Failed to update user weight log");
          weightLog = null;
        }
      }

      setCurrentUserObj({ 
        auth: currentUserObj.auth,
        data: newUserDoc,
        weight_log: weightLog || currentUserObj.weight_log,
      });

      // TBD - Update temp user data as well?
      return null;
    } catch (e) {
      console.error("Updating user failed: ",e);
      return null;
    }
  };

  // Assign React state and constants to context object
  const UserContextObject = {
    currentUser: currentUserObj,
    setCurrentUser,
    updateCurrentUserData,
    setTempUserData,
    tempUserData: tempUserDataObj,
  };

  return (
    <UserContext.Provider value={UserContextObject}>
      {props.children}
    </UserContext.Provider>
  );
};