import React, { useEffect, useState } from 'react';
import {
  Button,
  Stack,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Box,
  Divider,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  Heading,
  VStack,
} from '@chakra-ui/react';

import { UseUserContext } from "../../context/UserContext";
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebase/firebase';
import { genderRank } from '../../components/HeightPredictor';
import HumanHeightChart from '../../components/HumanHeightChart';

interface ChildProps {
  onAdvance: () => void,
  onRetreat: () => void,
}

const OnboardingHeight: React.FC<ChildProps> = (props) => {

  const [heightSliderValue, setHeightSliderValue] = useState(68.0);
  const [isLoading, setIsLoading] = useState(false);

  const globalPopulation = 8000000000; // 8 billion as of end of 2022
  // const globalGrowthPerSecond = 2.6; // 2.6 people per second on average

  const genderRanks = genderRank(heightSliderValue);

  const heightSliderLabelStyles = {
    mt: '3',
    ml: '-2.5',
    fontSize: 'sm',
    w: '12',
  }

  const { currentUser, updateCurrentUserData } = UseUserContext();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const inchToFootString = (inches: number) => {
    return `${Math.floor(inches/12)}' ${Math.round(inches - 12 * Math.floor(inches/12))}"`;
  }

  const submitUpdateProfile = async () => {
    if (!!heightSliderValue) {
      console.log("Submitting profile update...");
      const profileUpdate = {
        height_in: Number(heightSliderValue),
      }
      setIsLoading(true);
      await updateCurrentUserData(currentUser.auth.uid!, profileUpdate);
      console.log("User profile updated...");
      logEvent(analytics, 'profile_updated', {
        uid: currentUser.auth.uid,
        onboard_stage: "onboarded_height",
      });
      setIsLoading(false);
      props.onAdvance(); // Advance to next step
    }
  }

  return (
    <React.Fragment>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <VStack spacing={8} align="stretch">

          <Heading textAlign={"center"} fontSize={'6xl'}>How tall are you?</Heading>

          { HumanHeightChart(heightSliderValue) }

          <Divider hidden />

          <Slider defaultValue={68} min={56} max={80} step={1} onChange={(val) => setHeightSliderValue(val)}>
            <SliderMark value={5*12} {...heightSliderLabelStyles}>5 ft</SliderMark>
            <SliderMark value={5.5*12} {...heightSliderLabelStyles}>5' 6"</SliderMark>
            <SliderMark value={6*12} {...heightSliderLabelStyles}>6 ft</SliderMark>
            <SliderMark value={6.5*12} {...heightSliderLabelStyles}>6' 6"</SliderMark>
            <SliderMark
              value={heightSliderValue}
              textAlign='center'
              bg='green.500'
              color='white'
              mt='-10'
              ml='-5'
              w='16'
            >
              {inchToFootString(heightSliderValue)}
            </SliderMark>
            <SliderTrack bg='green.100'>
              <Box position='relative' right={10} />
              <SliderFilledTrack bg='green.500' />
            </SliderTrack>
            <SliderThumb boxSize={4} />
          </Slider>

          <Stat>
            <StatLabel>Taller than ~{Math.floor(globalPopulation*(1 - genderRanks.human)).toLocaleString()} humans on the planet</StatLabel>
            <StatNumber>You stand at a mighty {inchToFootString(heightSliderValue)} tall</StatNumber>
            <StatHelpText>
              <StatArrow color={"green.500"} type={genderRanks.human < 0.50 ? "increase" : "decrease"} />
              You are among the {genderRanks.human < 0.50 ? "tallest" : "shortest"} {(100*(genderRanks.human < 0.50 ? genderRanks.human : 1 - genderRanks.human)).toFixed(4)}% people in the world
            </StatHelpText>
          </Stat>

          <p>Your height information is a core part of our Basal Metabolic Rate calculations, so please get out some measuring tape if you're not sure!</p>
        
          <Stack spacing={6} direction={['column', 'row']}>
            {/* <Button
              bg={'red.400'}
              color={'white'}
              w="full"
              _hover={{
                bg: 'red.500',
              }}>
              Cancel
            </Button> */}
            <Button
              bg={'green.400'}
              color={'white'}
              w="full"
              isDisabled={!heightSliderValue || isLoading}
              isLoading={isLoading}
              onClick={submitUpdateProfile}
              _hover={{
                bg: 'green.500',
              }}>
              Yes, I am indeed {inchToFootString(heightSliderValue)} tall
            </Button>
          </Stack>

          <p><i>The tallest adult human on the planet was Robert Wadlow, standing at 8' 11" tall. The shortest adult was Chandra Dangi, at 21 inches.</i></p>
          <p><i>For maximum predictability, Basal.ai only supports a range of up to 3 standard-deviations of height based on global population samples.</i></p>

        </VStack>
      </Stack>
    </React.Fragment>
  );
}

export default OnboardingHeight;