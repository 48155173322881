import { useEffect } from "react";
import { ui, uiConfig } from "../firebase/firebase-ui";
import "firebaseui/dist/firebaseui.css";
import { Spinner, Stack, Text } from "@chakra-ui/react";

function Login () {

  useEffect(() => {
    ui.start("#firebaseui-auth-container", uiConfig);
  }, []);

  return (
    <>
      {/* <!-- The surrounding HTML is left untouched by FirebaseUI.
      Your app may use that space for branding, controls and other customizations.--> */}
      <div id="firebaseui-auth-container"></div>
      <div id="firebaseui-auth-loader">
        <Stack align={'center'}>
          <Text fontSize={'lg'} color={'gray.600'}>Loading...</Text>
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='green.500'
            size='xl'
          />
        </Stack>
      </div>
    </>
  );
}

export default Login;