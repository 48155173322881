// Example from https://react-chartjs-2.js.org/examples/
// https://react-chartjs-2.js.org/examples/line-chart

// import { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import { GlobalHeightData, getHeightIndex } from './HeightPredictor';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

const heightMin = 4*12+8-1;
const heightMax = 6*12+8+1;

export const options = {
  events: [],
  animation: {
    duration: 500
  },
  responsive: true,
  aspectRatio: 1,
  plugins: {
    filler: { propagate: false },
    legend: {
      // position: 'top' as const,
      display: false,
    },
    title: {
      display: false,
      // text: 'Measured Weight Log History',
    },
  },
  scales: {
    x: {
      min: heightMin,
      max: heightMax,
      display: false,
    },
    y: { display: false },
  },
  layout: {
    padding: 10
  }
};

export default function HumanHeightChart(userHeight: Number) {

  let heightData = GlobalHeightData;

  let userHeightPoints = heightData.heights_in.map(n => 0);
  const userHeightIndex = getHeightIndex(userHeight);
  const userHeightValue = heightData.probmass_human[userHeightIndex];
  userHeightPoints[userHeightIndex] = userHeightValue;

  const transformChartData = (userHeight: Number) => {
    const chartData = {
      labels: heightData.heights_in,
      datasets: [
         {
          label: "Your Height",
          data: userHeightPoints,
          backgroundColor: "#38A169",
          borderWidth: 0,
          pointBorderColor: "#FFF",
          pointBorderWidth: 1,
          pointRadius: userHeightPoints.map(d => d > 0 ? 5 : 0),
          pointStyle: "circle",
          fill: {
            target: 'origin',
            above: "#9AE6B4",   // Area will be red above the origin
            below: "#9AE6B4",    // And blue below the origin
          }
        },
        {
          label: "Global Height Distribution",
          data: heightData.probmass_human,
          pointBorderWidth: 0,
          pointRadius: 0,
          borderWidth: 2,
          borderColor: "#38A169",
        },
      ]
    }

    return chartData;
  }

  const transformedChartData = transformChartData(userHeight);

  return <Line options={options} data={transformedChartData} />;
}