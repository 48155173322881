import {
  Center,
  Box,
  Image,
  BoxProps,
  forwardRef,
} from '@chakra-ui/react';
import { Link } from "react-router-dom";

const LogoImage = forwardRef<BoxProps, "div">((props, ref) => (
    <Center>
      <Box boxSize={"sm"} alignContent={"center"} ref={ref} {...props}>
        <Link to="/">
          <Image src='/assets/images/basal_logo.png' />
        </Link>
      </Box>
    </Center>
  ))

export default LogoImage;