import {
  BrowserRouter as Router,
  Route,
  Routes,
  // Link,
  // useLocation
} from "react-router-dom";

import './App.css';

import AuthProvider from './components/AuthProvider';
import AuthGate from './components/AuthGate';
// import Navbar from "./components/Navbar";
import LandingPage from './pages/LandingPage';
import AuthPage from './pages/AuthPage';
import Dashboard from "./components/Dashboard";
import Profile from "./components/Profile";
import Goals from "./components/Goals";
import DashHome from "./components/DashHome";
import Progress from "./components/Progress";

function App() {

  return (
    <AuthProvider>
      <div className="App">
        <Router>

          <Routes>
            <Route index element={<LandingPage/>}/>
            <Route path="auth" element={<AuthPage />}/>
            <Route path="dashboard" element={<AuthGate><Dashboard/></AuthGate>}>
              <Route index element={<DashHome/>} />
              <Route path="profile" element={<Profile/>} />
              <Route path="goals" element={<Goals/>} />
              <Route path="progress" element={<Progress/>} />
              <Route path="settings" element={<div>Settings</div>} />
            </Route>
            {/* <Route path="/login" element={<Login/>}/> */}
            {/* <Route path="/recovery-password" element={<RecoveryPassword/>}/> */}
            {/* <Route path="*" element={<NotFound/>}/> */}
          </Routes>

        </Router>
      </div>
    </AuthProvider>
  );
}

export default App;
