import {
  Text,
} from '@chakra-ui/react';

const LogoText = () => {
  return (
    <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
      Basal AI
    </Text>
  );
}

export default LogoText;