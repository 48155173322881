// Example from https://react-chartjs-2.js.org/examples/
// https://react-chartjs-2.js.org/examples/line-chart

// import { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
// import { faker } from '@faker-js/faker';
// import WeightLog from '../types/WeightLog';
import WeightMeasurement from "../types/WeightMeasurement";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  aspectRatio: 1,
  plugins: {
    legend: {
      position: 'top' as const,
      // display: false,
    },
    title: {
      display: true,
      text: 'Measured Weight Log History',
    },
  },
};

// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

// export const data = {
//   labels,
//   datasets: [
//     {
//       label: 'Dataset 1',
//       data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
//       borderColor: 'rgb(255, 99, 132)',
//       backgroundColor: 'rgba(255, 99, 132, 0.5)',
//     },
//     {
//       label: 'Dataset 2',
//       data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
//       borderColor: 'rgb(53, 162, 235)',
//       backgroundColor: 'rgba(53, 162, 235, 0.5)',
//     },
//   ],
// };

// Line chart styling docs here:
// https://www.chartjs.org/docs/latest/charts/line.html

export default function WeightLogChart(weightLogHistory: WeightMeasurement[], showInterpolated: boolean) {

  const transformWeightLog = (weightLogHistory: WeightMeasurement[], showInterpolated: boolean) => {
    const filteredData = showInterpolated ? weightLogHistory : weightLogHistory.filter(d => !d.interpolated);
    const weightLogData = {
      labels: (filteredData).map(d => d.date),
      datasets: [
        {
          label: "True Weight Estimate",
          data: (filteredData).map(d => d.trueWeight),
          // pointBorderColor: (filteredData).map(d => d.interpolated ? "#FFF" : "#FFF"),

          pointBorderWidth: 0,
          pointRadius: 0, // (filteredData).map(d => d.interpolated ? 1 : 2),

          backgroundColor: (filteredData).map(d => "#38A169"),
          borderWidth: 2,
          borderColor: "#38A169",

          borderDash: (filteredData).map(d => 1),
          // borderColor: "#00B0F0",
          // backgroundColor: '#00B0F0',
          // pointRadius: (filteredData).map(d => d.interpolated ? 0 : 2),
        },
        {
          label: "Measured Weight (lbs)",
          data: (filteredData).map(d => d.value),
          // pointBorderColor: (filteredData).map(d => d.interpolated ? "#FFF" : "#FFF"),

          pointBorderWidth: 0,
          pointRadius: (filteredData).map(d => d.interpolated ? 0 : 1.50), // (filteredData).map(d => d.interpolated ? 1 : 2),

          backgroundColor: (filteredData).map(d => d.interpolated ? "#9AE6B4" : "#9AE6B4"), // (filteredData).map(d => d.interpolated ? "#8e8e8e" : "#38A169"),
          
          borderWidth: 1.50,
          borderColor: "#9AE6B4",

          // borderDash: (filteredData).map(d => d.interpolated ? 2 : 0),
          // borderColor: "#00B0F0",
          // backgroundColor: '#00B0F0',
          // pointRadius: (filteredData).map(d => d.interpolated ? 0 : 2),
        },
      ]
    }

    return weightLogData;
  }

  const transformedChartData = transformWeightLog(weightLogHistory, showInterpolated);

  return <Line options={options} data={transformedChartData} />;
}