// Example from https://react-chartjs-2.js.org/examples/
// https://react-chartjs-2.js.org/examples/line-chart

// import { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

// const heightMin = 4*12+8-1;
// const heightMax = 6*12+8+1;

export default function WeightForecastChart(WeightSimulation: {
    f_day: number[]; f_wtarget: any[]; f_y: any[][]; // const userHeightIndex = getHeightIndex(userHeight);
    // const userHeightIndex = getHeightIndex(userHeight);
    // const userHeightValue = heightData.probmass_human[userHeightIndex];
    // userHeightPoints[userHeightIndex] = userHeightValue;
    f_y_slim: any[][]; user_target: any; user_weight: any;
  }, select_sims: {
    closest: any[][]; //success: any[][]; decent: any[][]; failure: any[][]; // backgroundColor: (filteredData).map(d => "#38A169"),
  }) {

  let simData = WeightSimulation;

  const options = {
    events: [],
    animation: {
      duration: 500
    },
    responsive: true,
    aspectRatio: 1,
    plugins: {
      filler: { propagate: false },
      legend: {
        // position: 'top' as const,
        display: false,
      },
      title: {
        display: false,
        // text: 'Measured Weight Log History',
      },
    },
    scales: {
      x: {
        min: 0,
        max: simData.f_day.length,
        display: true,
      },
      y: { 
        display: true,
        min: simData.user_target-5,
        max: simData.user_weight+5,
        title: {
          text: "Weight Forecast (lbs)",
          display: true
        },
      },
    },
    layout: {
      padding: 10
    }
  };

  // let userHeightPoints = heightData.heights_in.map(n => 0);
  // const userHeightIndex = getHeightIndex(userHeight);
  // const userHeightValue = heightData.probmass_human[userHeightIndex];
  // userHeightPoints[userHeightIndex] = userHeightValue;

  const transformChartData = () => {
    // const selected_sims = select_sims.success.concat(select_sims.decent).concat(select_sims.failure);
    const selected_sims = select_sims.closest;
    const chartData = {
      labels: simData.f_day.map(d => d),
      datasets: [
        {
          label: "True Weight Estimate",
          data: simData.f_wtarget.map(d => d),
          // pointBorderColor: (filteredData).map(d => d.interpolated ? "#FFF" : "#FFF"),

          pointBorderWidth: 0,
          pointRadius: 0, // (filteredData).map(d => d.interpolated ? 1 : 2),

          // backgroundColor: (filteredData).map(d => "#38A169"),
          borderWidth: 2,
          borderColor: "#38A169",

          // borderDash: (filteredData).map(d => 1),
          // borderColor: "#00B0F0",
          // backgroundColor: '#00B0F0',
          // pointRadius: (filteredData).map(d => d.interpolated ? 0 : 2),
        },
        ...selected_sims.map((sim, sim_i) => ({
          label: `Sim [${sim_i}]`,
          data: sim.map(d => d),

          // pointBorderWidth: 1.0,
          pointRadius: 1.0,
          pointBorderColor: Number(sim.slice(-1)) <= simData.user_target ? "rgba(56,161,105,0.40)" : Number(sim.slice(-1)) <= simData.user_weight ? "rgba(49,130,206,0.20)" : "rgba(100,100,100,0.10)",

          borderWidth: 0.50,
          // borderColor: Number(sim.slice(-1)) <= simData.user_target ? "rgba(56,161,105,0.40)" : Number(sim.slice(-1)) <= simData.user_weight ? "rgba(49,130,206,0.20)" : "rgba(100,100,100,0.10)",
        })),
        // {
        //   label: "True Weight Estimate",
        //   data: simData.f_y[0].map(d => d),
        //   // pointBorderColor: (filteredData).map(d => d.interpolated ? "#FFF" : "#FFF"),

        //   pointBorderWidth: 0,
        //   pointRadius: 0, // (filteredData).map(d => d.interpolated ? 1 : 2),

        //   // backgroundColor: (filteredData).map(d => "#38A169"),
        //   borderWidth: 2,
        //   borderColor: "rgba(56,161,105,0.1)",

        //   // borderDash: (filteredData).map(d => 1),
        //   // borderColor: "#00B0F0",
        //   // backgroundColor: '#00B0F0',
        //   // pointRadius: (filteredData).map(d => d.interpolated ? 0 : 2),
        // },
        //  {
        //   label: "Your Height",
        //   data: userHeightPoints,
        //   backgroundColor: "#38A169",
        //   borderWidth: 0,
        //   pointBorderColor: "#FFF",
        //   pointBorderWidth: 1,
        //   pointRadius: userHeightPoints.map(d => d > 0 ? 5 : 0),
        //   pointStyle: "circle",
        //   fill: {
        //     target: 'origin',
        //     above: "#9AE6B4",   // Area will be red above the origin
        //     below: "#9AE6B4",    // And blue below the origin
        //   }
        // },
        // {
        //   label: "Global Height Distribution",
        //   data: heightData.probmass_human,
        //   pointBorderWidth: 0,
        //   pointRadius: 0,
        //   borderWidth: 2,
        //   borderColor: "#38A169",
        // },
      ]
    }

    return chartData;
  }

  const transformedChartData = transformChartData();

  return <Line options={options} data={transformedChartData} />;
}