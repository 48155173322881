import React, { useEffect, useState } from 'react';
import {
  Button,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  Heading,
  VStack,
  Radio,
  RadioGroup,
  Center,
} from '@chakra-ui/react';

import { UseUserContext } from "../../context/UserContext";
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebase/firebase';
import { genderRank, genderChance } from '../../components/HeightPredictor';
import SexHeightChart from '../../components/SexHeightChart';

enum Sexes {
  UNKNOWN = 'human',
  MALE = 'male',
  FEMALE = 'female',
}

interface ChildProps {
  onAdvance: () => void,
  onRetreat: () => void,
}

const OnboardingSex: React.FC<ChildProps> = (props) => {

  const { currentUser, updateCurrentUserData } = UseUserContext();
  

  // From previous sessions
  const globalPopulation = 8000000000; // 8 billion as of end of 2022
  const userHeight = Number(currentUser.data.height_in || 0);

  const genderRanks = genderRank(userHeight);
  const genderChances = genderChance(userHeight);

  const assumedSex = genderChances.male >= genderChances.female ? Sexes.MALE : Sexes.FEMALE;

  const [userSex, setUserSex] = useState<Sexes>(assumedSex);
  const [isLoading, setIsLoading] = useState(false);

  const sexColor = userSex === Sexes.MALE ? "blue" : "purple";

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const inchToFootString = (inches: number) => {
    return `${Math.floor(inches/12)}' ${Math.round(inches - 12 * Math.floor(inches/12))}"`;
  }

  const submitUpdateProfile = async () => {
    if (!!userSex) {
      console.log("Submitting profile update...");
      const profileUpdate = {
        gender: String(userSex),
      }
      setIsLoading(true);
      await updateCurrentUserData(currentUser.auth.uid!, profileUpdate);
      console.log("User profile updated...");
      logEvent(analytics, 'profile_updated', {
        uid: currentUser.auth.uid,
        onboard_stage: "onboarded_sex",
      });
      setIsLoading(false);
      props.onAdvance(); // Advance to next step
    }
  }

  return (
    <React.Fragment>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <VStack spacing={8} align="stretch">

        <Heading textAlign={"center"} fontSize={'6xl'}>Did we guess correctly?</Heading>

        <Heading textAlign={"center"} fontSize={'2xl'}>There's a {(100*genderChances[assumedSex]).toFixed(4)}% chance you are a {assumedSex} based solely your height at {inchToFootString(userHeight)}</Heading>

          { SexHeightChart(userHeight, userSex) }

          <Center>
            <RadioGroup defaultValue={assumedSex} onChange={(nextValue: Sexes) => setUserSex(nextValue)}>
              <Stack spacing={4} direction='column'>
                <Radio size='lg' name={Sexes.MALE} colorScheme='purple' value={Sexes.FEMALE}>{assumedSex === "female" ? "Correct - I'm a female" : "Incorrect - I'm a female"}</Radio>
                <Radio size='lg' name={Sexes.FEMALE} colorScheme='blue' value={Sexes.MALE}>{assumedSex === "female" ? "Incorrect - I'm a male" : "Correct - I'm a male"}</Radio>
              </Stack>
            </RadioGroup>
          </Center>

          <Stat>
            <StatLabel>Taller than ~{Math.floor(globalPopulation*(userSex === "male" ? 0.5047 : 0.4953)*(1 - genderRanks[userSex])).toLocaleString()} {userSex}s on the planet</StatLabel>
            <StatNumber>Your height stats as a {inchToFootString(userHeight)} {userSex}</StatNumber>
            <StatHelpText>
              <StatArrow color={"green.500"} type={genderRanks.human < 0.50 ? "increase" : "decrease"} />
              You are among the {genderRanks[userSex] < 0.50 ? "tallest" : "shortest"} {(100*(genderRanks[userSex] < 0.50 ? genderRanks[userSex] : 1 - genderRanks[userSex])).toFixed(4)}% {userSex}s in the world
            </StatHelpText>
          </Stat>

          <p>Your physical sex is another core part of our Basal Metabolic Rate calculations. Males and females have different BMR equations.</p>
        
          <Stack spacing={6} direction={['column', 'row']}>
            {/* <Button
              bg={'red.400'}
              color={'white'}
              w="full"
              _hover={{
                bg: 'red.500',
              }}>
              Cancel
            </Button> */}
            <Button
              bg={`${sexColor}.400`}
              color={'white'}
              w="full"
              isDisabled={!userHeight || isLoading}
              isLoading={isLoading}
              onClick={submitUpdateProfile}
              _hover={{
                bg: `${sexColor}.500`,
              }}>
              {userSex === assumedSex ? `The stats got me! I am indeed a ${assumedSex}.` : `Nope! I beat the stats. I am a ${userSex}.`}
            </Button>
          </Stack>

          {/* <p><i>There are slightly more males than females on the planet, with roughly 101.68 males to every 100 females. That being said, some areas of the world have different numbers, such as Hong Kong at 54.2% female!</i></p> */}

        </VStack>
      </Stack>
    </React.Fragment>
  );
}

export default OnboardingSex;