import {
  Heading,
  Stack,
  useColorModeValue,
  Container,
  Text,
} from '@chakra-ui/react';

import GoalForm from './GoalForm';
import LogoImage from './LogoImage';

export default function Goals(): JSX.Element {

  // const { currentUser, setTempUserData, tempUserData, updateCurrentUserData } = UseUserContext();
  // const userData = tempUserData || currentUser.data;
  
  return (
    <Container maxW={'3xl'}>

      {/* <Heading lineHeight={1.5} fontSize={{ base: '5xl', sm: '5xl' }}>
        Goals
      </Heading> */}

      <Stack align={'left'}>
        <LogoImage boxSize="xs"/>
        <Heading textAlign={"left"} fontSize={'4xl'}>Update your Goal</Heading>
        <Text fontSize={'lg'} color={'gray.600'}>
          It's time to get serious! Based on your BMR, we can calculate a new <strong>daily Calorie budget</strong> once you give us a goal to hit together!
        </Text>
      </Stack>

      <Stack
        spacing={4}
        w={'full'}
        maxW={'3xl'}
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={12}>

        <Heading lineHeight={1.1} fontSize={{ base: '2xl', sm: '3xl' }}>
          Your Goal
        </Heading>

        <GoalForm />
          
      </Stack>

      {/* <div>Dev Notes TBD: Show goals chart and trend lines</div>
      <div>Extrapolate missing days</div>
      <div>Warn when losing more than 1 lb per week</div>
      <div>Show past goals and trend lines as well</div>
      <div>Allow deletion of past goals, but warn about self-honesty</div> */}
    </Container>
  );
}
