// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";
import { 
  getFirestore, doc, getDoc, setDoc, updateDoc, serverTimestamp,
  // Firestore 
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import moment from "moment";
import { getStripePayments } from "@stripe/firestore-stripe-payments";

import UserData from "../types/UserData";
import WeightLog from "../types/WeightLog";
import WeightMeasurement from "../types/WeightMeasurement";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyAsGPnmEWmK6cQepE9FoKH3w3DJF9seQ2Q",
//   authDomain: "basal-ai.firebaseapp.com",
//   projectId: "basal-ai",
//   storageBucket: "basal-ai.appspot.com",
//   messagingSenderId: "595008112003",
//   appId: "1:595008112003:web:2bbc715d995e41ecbc22b6",
//   measurementId: "G-18TK9KM2BG"
// };

const firebaseConfig = {
  apiKey: "AIzaSyC9ixiCn5gTpUuyAi-4cVeVM4tYA6n7gA0",
  authDomain: "bmr-tracker-dev.firebaseapp.com",
  projectId: "bmr-tracker-dev",
  storageBucket: "bmr-tracker-dev.appspot.com",
  messagingSenderId: "244871982627",
  appId: "1:244871982627:web:bb885f28a6f60e4fc44b96",
  measurementId: "G-WVS4LSKG3Q" // Analytics ID
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Init firebase analytics
export const analytics = getAnalytics(app);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

// Init firebase functions
export const functions = getFunctions(app);

// Initialize Stripe
export const payments = getStripePayments(app, {
  productsCollection: "products",
  customersCollection: "users",
});

// ACCESS FIREBASE BELOW
// https://firebase.google.com/docs/web/setup

// import { initializeApp } from 'firebase/app';
// import { getFirestore, collection, getDocs } from 'firebase/firestore';
// // Follow this pattern to import other Firebase services
// // import { } from 'firebase/<service>';

export const db = getFirestore(app);

// Create checkout session
// export async function createCheckoutSession() {
//   const session = await createCheckoutSession(payments, {
//     price: myPriceId,
//     success_url: "https://example.com/payments/success",
//     cancel_url: "https://example.com/payments/cancel",
//   });
//   window.location.assign(session.url);
// }

// onCurrentUserSubscriptionUpdate(
//   payments,
//   (snapshot) => {
//     for (const change in snapshot.changes) {
//       if (change.type === "added") {
//         console.log(`New subscription added with ID: ${change.subscription.id}`);
//       }
//     }
//   }
// );

interface createStripeCustomerPortalLinkResInterface { url?: string | URL }
const createStripeCustomerPortalLinkRef = httpsCallable<any, createStripeCustomerPortalLinkResInterface>(functions, 'ext-firestore-stripe-payments-createPortalLink');
export const createStripeCustomerPortalLink = async () => {
  try {
    const { data } = await createStripeCustomerPortalLinkRef({
      returnUrl: window.location.href,
      locale: "auto", // Optional, defaults to "auto"
      // configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
    });
    if (!data.url) throw new Error("No url returned from createStripeCustomerPortalLinkRef");
    window.location.assign(data.url);
    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
}

// Create stripe customer portal
// const functionRef = firebase
//   .app()
//   .functions('us-central1')
//   .httpsCallable('ext-firestore-stripe-payments-createPortalLink');
// const { data } = await functionRef({
//   returnUrl: window.location.origin,
//   locale: "auto", // Optional, defaults to "auto"
//   configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
// });
// window.location.assign(data.url);

// Create a user document
export async function addUser(uid: string, userData: UserData): Promise<UserData | Error> {
  try {
    if (!uid) throw new Error("No user id defined");
    const docRef = doc(db, "users", uid);
    const newUserDoc = { 
      ...userData,
      lastModified: serverTimestamp()
    };
    await setDoc(docRef, newUserDoc);
    const updatedUserDoc = await getUser(uid);
    return updatedUserDoc;
  } catch (e) {
    console.error("Create user document failed: ",e);
    return new Error("Create user document failed");
  }
}

// Get a user document
export async function getUser(uid: string | null | undefined): Promise<UserData | Error> {
  try {
    if (!uid) throw new Error("No user id defined");
    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);
    let userData: UserData;
    if (docSnap.exists()) {
      userData = docSnap.data();
      return userData;
    } else {
      // No user document found. Create one
      console.log("No document found for uid: ",uid,". Creating one now.");
      userData = await addUser(uid, {});
      logEvent(analytics, 'user_signup', {
        uid: uid
      });
      return userData;
    } 
  } catch (e) {
    console.error("Retrieving user data failed: ",e);
    return new Error("Retrieving user data failed");
  }
}

// Update a user document 
export async function updateUser(uid: string | null | undefined, userData: UserData) : Promise<UserData | Error> {
  try {
    if (!uid) throw new Error("No user id defined");
    const docRef = doc(db, "users", uid);
    const newUserDoc = { 
      ...userData,
      lastModified: serverTimestamp()
    };
    await updateDoc(docRef, newUserDoc);
    const updatedUserDoc = await getUser(uid);
    return updatedUserDoc;
  } catch (e) {
    console.error("Update user document failed: ",e);
    return new Error("Update user document failed");
  }
}

// Log a user weight measurement 
export async function getUserWeightLog(uid: string | null | undefined): Promise<WeightLog | Error >{
  let weightLog: WeightLog;
  try {
    if (!uid) throw new Error("No user id defined");
    const docRef = doc(db, "user-weight-logs", uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      weightLog = docSnap.data();
    } else {
      console.log("No weight log found for user uid: ",uid);
      weightLog = { history: [] }; 
      const docSnap = await setDoc(docRef, weightLog);
    } 
  } catch (e) {
    console.error("Failed to retrieve user weight log: ",e);
    return new Error("Failed to retrieve user weight log");
  }
  return weightLog;
}

// Log a user weight measurement 
export async function logUserWeight(uid: string | null | undefined, measuredWeight: number, measuredDate?: string ) : Promise<WeightLog | Error> {
  try {
    if (!measuredDate) {
      measuredDate = moment().format("YYYY-MM-DD");
      console.log("Computing implied measurement date of: ",measuredDate);
    }
    if (!uid) throw new Error("No user id defined");
    let prevWeightLog = await getUserWeightLog(uid);
    if (prevWeightLog instanceof Error) {
      console.error("Failed to retrieve user weight log");
      return new Error("Failed to retrieve user weight log");
    }
    const docRef = doc(db, "user-weight-logs", uid);
    let insertVal: WeightMeasurement = { date: measuredDate, value: measuredWeight }; 
    if (!prevWeightLog.history) {
      // Simply add the measurement as a new array
      await updateDoc(docRef, { history: [insertVal] });
    } else {
      // Remove any repeated values prior to insertion
      let newWeightLogHistory = prevWeightLog.history.filter(d => d.date !== measuredDate);
      newWeightLogHistory = [ insertVal, ...newWeightLogHistory];
      await updateDoc(docRef, { history: newWeightLogHistory });
    }
    // Fetch updated weight log
    let updatedWeightLog = await getUserWeightLog(uid);
    return updatedWeightLog;
  } catch (e) {
    console.error("Logging user weight failed: ",e);
    return new Error("Logging user weight failed");
  }
}