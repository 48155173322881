// v9 compat packages are API compatible with v8 code
import firebase from 'firebase/compat/app';
import { auth } from "./firebase";
import * as firebaseui from "firebaseui";
import { getAdditionalUserInfo } from 'firebase/auth';
import { UseUserContext } from '../context/UserContext';
// import 'firebase/compat/auth';
// import 'firebase/compat/firestore';

// Followed steps here:
// https://firebase.google.com/docs/auth/web/firebaseui

// Initialize the FirebaseUI Widget using Firebase.
export let ui = new firebaseui.auth.AuthUI(auth);

export const uiConfig = {
  // signInOptions: [
  //   {
  //     provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
  //     requireDisplayName: false
  //   }
  // ],

  // Whether to upgrade anonymous users should be explicitly provided.
  // The user must already be signed in anonymously before FirebaseUI is
  // rendered.
  // autoUpgradeAnonymousUsers: true,

  signInOptions: [
    // Leave the lines as is for the providers you want to offer your users.
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    // firebase.auth.GithubAuthProvider.PROVIDER_ID,
    // firebase.auth.EmailAuthProvider.PROVIDER_ID,
    // firebase.auth.PhoneAuthProvider.PROVIDER_ID
  ],

  callbacks: {
    // From: https://firebase.google.com/docs/auth/web/firebaseui
    signInSuccessWithAuthResult: function(authResult: any, redirectUrl: any) {
      // User successfully signed in.
      // Return type determines whether we continue the redirect automatically
      // or whether we leave that to developer to handle.
      // const successResponse = async () => {
      //   // const user = await getAdditionalUserInfo(result, firebase);
      //   // dispatchEvent({ type: 'LOGIN_USER', user });
      //   // navigate('/profile')
      // }

      // const { setCurrentUser } = UseUserContext();
      // if (authResult.user?.uid?) {
        
      // }

      window.location.reload();
      return false; // Avoid redirects after sign in
    },
    // signInFailure callback must be provided to handle merge conflicts which
    // occur when an existing credential is linked to an anonymous user.
    // signInFailure: function(error: any) {
    //   // For merge conflicts, the error.code will be
    //   // 'firebaseui/anonymous-upgrade-merge-conflict'.
    //   if (error.code != 'firebaseui/anonymous-upgrade-merge-conflict') {
    //     return Promise.resolve();
    //   }
    //   // The credential the user tried to sign in with.
    //   var cred = error.credential;
    //   // Copy data from anonymous user to permanent user and delete anonymous
    //   // user.
    //   // ...
    //   // Finish sign-in after data is copied.
    //   return firebase.auth().signInWithCredential(cred);
    // },
    uiShown: function() {
      // The widget is rendered.
      // Hide the loader.
      document.getElementById('firebaseui-auth-loader')!.style.display = 'none';
    }
  },
  // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
  signInFlow: 'popup',
  // signInSuccessUrl: '/',
  // Terms of service url.
  // tosUrl: 'tos',
  // Privacy policy url.
  // privacyPolicyUrl: 'policy',
  // Other config options...
}