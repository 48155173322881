import React, { useEffect, useState } from 'react';
import {
  Button,
  Stack,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Box,
  Divider,
  Heading,
  VStack,
  Center,
  Text,
  RadioGroup,
  Radio,
  Progress,
  Badge,
} from '@chakra-ui/react';

import { UseUserContext } from "../../context/UserContext";
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebase/firebase';
import WeightForecastChart from '../../components/WeightForecastChart';
import RunWeightSimulation from '../../components/WeightProjector';
import { calcBMI } from '../../bmr/bmr';

interface ChildProps {
  onAdvance: () => void,
  onRetreat: () => void,
}

enum GoalTimelines {
  M12 = '12',
  M3 = '3',
  M1 = '1',
}

const heightSliderLabelStyles = {
  mt: '3',
  ml: '-2.5',
  fontSize: 'xs',
  w: '24',
}

const OnboardingWeight: React.FC<ChildProps> = (props) => {

  const { currentUser, updateCurrentUserData } = UseUserContext();

  const [isLoading, setIsLoading] = useState(false);
  const [goalTimeline, setGoalTimeline] = useState<GoalTimelines>(GoalTimelines.M12);
  const goalTimelineDays = goalTimeline === GoalTimelines.M12 ? 365 : goalTimeline === GoalTimelines.M3 ? 90 : 30;

  const startingWeight = Number(currentUser.data.last_weight_lbs);

  const aggressiveTarget = Math.round(startingWeight - (1.5*goalTimelineDays/7));
  const mediumTarget = Math.round(startingWeight - (1.00*goalTimelineDays/7));
  const lowTarget = Math.round(startingWeight - (0.50*goalTimelineDays/7));

  const userBMIStats = calcBMI(Number(startingWeight), Number(currentUser.data.height_in));

  const minWeight = Math.max(userBMIStats.goalBounds.minGoalWeight, aggressiveTarget)

  const maxTargetWeight = Math.max(startingWeight, minWeight, Math.min(userBMIStats.goalBounds.maxGoalWeight, startingWeight));

  const initialTargetWeight = Math.max(Math.max(startingWeight - 5, minWeight), mediumTarget);

  const [targetWeightValue, setTargetWeightValue] = useState(String(initialTargetWeight));
  // const userBirthday = moment(currentUser.data.birthday).toDate();
  // const userAge = moment().diff(userBirthday, 'years', false);
  // const userBMR = calcBMRMSJ(currentUser.data.weight, Number(currentUser.data.height_in), userAge, String(currentUser.data.gender));

  const handleTargetWeightChange = (valueString: string | number) => setTargetWeightValue(String(valueString));
  const clampWeight = (valueString: string | number) => Math.max(Math.min(Number(valueString) || initialTargetWeight, maxTargetWeight), aggressiveTarget);

  const handleSetGoalTimeline = (value: GoalTimelines) => {
    setGoalTimeline(value);
    setTargetWeightValue(String(clampWeight(targetWeightValue)));
  };

  const clampedTargetWeight = clampWeight(targetWeightValue);
  const numericTargetWeight = Number(targetWeightValue);
  const isValidTargetWeight = !(!numericTargetWeight ||  (numericTargetWeight !== clampedTargetWeight));

  if (numericTargetWeight && numericTargetWeight !== clampedTargetWeight) setTargetWeightValue(String(clampedTargetWeight));

  const WeightSimulation = RunWeightSimulation(startingWeight, clampedTargetWeight, goalTimelineDays);

  // const simulatedPPW = WeightSimulation.u_ppw;

  function rankings(arr: any[]) {
    const sorted = [...arr].sort((a, b) => a - b);
    return arr.map((x) => sorted.indexOf(x) + 1);
  };  

  let sim_closest = [];
  const sim_closest_idx = rankings(WeightSimulation.f_rmse);

  for (let i = 0; i < sim_closest_idx.length; i++) {
    if (sim_closest_idx[i] <= 10) {
      sim_closest.push(WeightSimulation.f_y[i]);
    }
  }

  const sim_successes = WeightSimulation.f_y.filter(s => Number(s.slice(-1)) <= numericTargetWeight);
  // const sim_decents = WeightSimulation.f_y.filter(s => Number(s.slice(-1)) <= startingWeight && Number(s.slice(-1)) > numericTargetWeight);
  // const sim_failures = WeightSimulation.f_y.filter(s => Number(s.slice(-1)) > startingWeight);

  const chance_success = Number(sim_successes.length) / WeightSimulation.NUM_SIMS;
  // const chance_decent = Number(sim_decents.length) / WeightSimulation.NUM_SIMS;
  // const chance_failure = Number(sim_failures.length) / WeightSimulation.NUM_SIMS;

  const successChanceLabel = chance_success >= 0.50 ? "High" : chance_success >= 0.25 ? "Good" : chance_success >= 0.15 ? "Avg" : "Low";
  const successChanceColor = chance_success >= 0.50 ? "green" :chance_success >= 0.25 ? "teal" : chance_success >= 0.15 ? "orange" : "red";

  const select_sims = {
    closest: sim_closest,
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const submitUpdateProfile = async () => {
    if (isValidTargetWeight) {
      console.log("Submitting profile update...");
      const profileUpdate = {
        goal_weight_lbs: numericTargetWeight,
        goal_timeline: goalTimeline,
        goal_sim_odds: chance_success,
      }
      setIsLoading(true);
      await updateCurrentUserData(currentUser.auth.uid!, profileUpdate);
      console.log("User profile updated...");
      logEvent(analytics, 'profile_updated', {
        uid: currentUser.auth.uid,
        onboard_stage: "onboarded_goal",
      });
      setIsLoading(false);
      props.onAdvance(); // Advance to next step
    }
  }

  return (
    <React.Fragment>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <VStack spacing={8} align="stretch">

          <Heading textAlign={"center"} fontSize={'5xl'}>What's your target weight?</Heading>
          <Text fontSize='2xl' textAlign={"center"}>Let's put together your Basal metabolic weight loss plan</Text>

          <Text fontSize='medium' textAlign={"center"} fontWeight="bold">Your Goal Weight</Text>
          <Slider defaultValue={numericTargetWeight} min={minWeight} max={maxTargetWeight} step={1} onChange={(val) => handleTargetWeightChange(val)}>
            { (minWeight < aggressiveTarget) && <SliderMark value={aggressiveTarget} {...heightSliderLabelStyles}>-1.5 lbs / week</SliderMark> }
            { (minWeight < mediumTarget) && <SliderMark value={mediumTarget} {...heightSliderLabelStyles}>-1.0 lbs / week</SliderMark> }
            { (minWeight < lowTarget) && <SliderMark value={lowTarget} {...heightSliderLabelStyles}>-0.5 lbs / week</SliderMark> }
            <SliderMark value={startingWeight} {...heightSliderLabelStyles}>+0</SliderMark> 
            <SliderMark
              value={Number(clampedTargetWeight)}
              textAlign='center'
              bg='green.500'
              color='white'
              mt='-10'
              ml='-5'
              w='24'
            >
              {clampedTargetWeight} lbs
            </SliderMark>
            <SliderTrack bg='green.100'>
              <Box position='relative' right={10} />
              <SliderFilledTrack bg='green.500' />
            </SliderTrack>
            <SliderThumb boxSize={4} />
          </Slider>

          <Center>
            <RadioGroup defaultValue={GoalTimelines.M12} onChange={(newTimeline: GoalTimelines) => handleSetGoalTimeline(newTimeline)}>
              <Text fontSize='medium' textAlign={"center"} fontWeight="bold">Goal Timeline</Text>
              <Stack spacing={5} direction='row'>
                <Radio colorScheme='green' value={GoalTimelines.M12} size={"sm"}>
                  12 Months
                </Radio>
                <Radio colorScheme='green' value={GoalTimelines.M3} size={"sm"}>
                  3 Months
                </Radio>
                <Radio colorScheme='green' value={GoalTimelines.M1} size={"sm"}>
                  1 Month
                </Radio>
              </Stack>
            </RadioGroup>
          </Center>

          {isValidTargetWeight && (

            <Stack spacing={5}>
              <Text fontSize='medium' textAlign={"center"} fontWeight="bold">Simulated Odds: <Badge colorScheme={successChanceColor}>{successChanceLabel}</Badge></Text>
              <Progress colorScheme={successChanceColor} size='sm' value={100*chance_success} />
            </Stack>

          )}

          { isValidTargetWeight && (
            <React.Fragment>
              { WeightForecastChart(WeightSimulation, select_sims) }
            </React.Fragment>
          )}

          <Divider hidden />

          <Text fontSize='xl' textAlign={"center"}>Don't worry - we can update your goal later. We'll also dynamically update your goal as needed.</Text>

          <Stack spacing={6} direction={['column', 'row']}>
            {/* <Button
              bg={'red.400'}
              color={'white'}
              w="full"
              _hover={{
                bg: 'red.500',
              }}>
              Cancel
            </Button> */}

            {numericTargetWeight && (
              <Button
                bg={'green.400'}
                color={'white'}
                w="full"
                isDisabled={!isValidTargetWeight}
                isLoading={isLoading}
                onClick={submitUpdateProfile}
                _hover={{
                  bg: 'green.500',
                }}>
                My goal is to hit {targetWeightValue} lbs in {goalTimelineDays} days
              </Button>
            )}
            
          </Stack>

        </VStack>
      </Stack>
    </React.Fragment>
  );
}

export default OnboardingWeight;