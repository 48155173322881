import moment from "moment";

import UserData from "../types/UserData";
import WeightMeasurement from "../types/WeightMeasurement";
// import { sort } from "../utils/utils";

// Control variables
const caloriesPerPound = 3500;

const calcDaysLeft = (goalDate: string | undefined) => {
  const goalMoment = moment(goalDate);
  const daysFromNow = goalMoment.diff(moment(), "days");
  return daysFromNow;
}

// const WeightStringToNum = (weightString: string | number | undefined) => {
//   if (typeof weightString === "number") return weightString;
//   if (!weightString) return 0;
//   if (!weightString.trim()) return 0;
//   const weightNum = Number(weightString);
//   if (!weightNum) return 0;
//   return weightNum;
// }

enum Sexes {
  MALE = 'male',
  FEMALE = 'female',
}

// BMI calculations from https://www.cdc.gov/healthyweight/assessing/bmi/adult_bmi/english_bmi_calculator/bmi_calculator.html

export const calcBMI = (weight_lbs: number, height_in: number) => {
  // Calculate BMI
  const bmi = Math.round(weight_lbs * 703 * 10 / height_in / height_in) / 10;
  
  // Calculate min weight and max weight for normal BMI
  const minBound = Math.round(12 * height_in * height_in / 703);
  const minweight = Math.round(18.5 * height_in * height_in / 703);
  const maxweight = Math.round(24.9 * height_in * height_in / 703);

  const minGoalWeight = Math.round(17 * height_in * height_in / 703);
  const maxGoalWeight = Math.round(40 * height_in * height_in / 703);

  let bmiCategory = bmi <= 18.5 ? 'underweight' : bmi <= 24.9 ? 'normal' : bmi <= 29.9 ? 'overweight' : 'obese';
  if (bmi <= 12) bmiCategory = "error";

  return { bmi, minweight, maxweight, bmiCategory, goalBounds: { minGoalWeight, maxGoalWeight }, bmiScale: { severeThin: 16, moderateThin: 17, mildThin: 18.5, normal: 25, overWeight: 30, obese1: 35, obese2: 40 } };
}

//   //---------Show divs based on BMI value--------	
    
//     if (bmi <= 12) {
//       //error
//       $(".bmiresults").hide();
//       $("#error").show();
//       $("#bmi_table").hide();
//     }
//     else if (bmi > 12 && bmi < 18.5) {
//       //underweight
//       $(".bmiresults").hide();
//       $(".bmi_type").empty().append("Underweight");
//       $("#underweight").show();
//       $('[class^="T_"]').addClass("highlight").not(".T_underweight").removeClass("highlight");
//       $("#bmi_table").show();
//     }
//     else if (bmi >= 18.5 && bmi <25){
//       //normal
//       $(".bmiresults").hide();
//       $(".bmi_type").empty().append("Healthy Weight");
//       $("#normal").show();
//       $('[class^="T_"]').addClass("highlight").not(".T_normal").removeClass("highlight");
//       $("#bmi_table").show();
      
//     }
//     else if (bmi >= 25 && bmi <30){
//       //overweight
//       $(".bmiresults").hide();
//       $(".bmi_type").empty().append("Overweight");
//       $("#overweight").show();
//       $("#obese_moreinfo").show();
//       $('[class^="T_"]').addClass("highlight").not(".T_overweight").removeClass("highlight");
//       $("#bmi_table").show();			
//     }
//     else if (bmi >=30) {
//       //obese
//       $(".bmiresults").hide();
//       $(".bmi_type").empty().append("Obesity");
//       $("#obese").show();
//                                       $("#obese_moreinfo").show();
//       $('[class^="T_"]').addClass("highlight").not(".T_obese").removeClass("highlight");
//       //$(".T_obese").removeClass().addClass("highlight");
//       $("#bmi_table").show();
//     }
//     else {
//     //error
//     $(".bmiresults").hide();
//     $("#error").show();
//     $("#bmi_table").hide();
      
//     }
//   //------------
  
    
//   });
  
  
  
//   });
  
//   /* End Raw JS */
  


const calcAge = (birthday: string | undefined) => moment().diff(moment(birthday), "years");

// Calcuates BMR using the Mifflin-St Jeor equation from physical properties
// BMR X 1.0: If you are bedridden
// BMR X 1.2: If you are sedentary = little to no exercise in a day
// BMR X 1.375: If you are slightly active = light exercise/sports 1-3 days/week
// BMR X 1.55: If you are moderately active = moderate exercise/sports 3-5 days/week
// BMR X 1.725: If you are very active = hard exercise/sports 6-7 days a week
// BMR X 1.9: If you are extra active = very hard exercise/sports and physical job OR 2x training
const calcBMRMSJ = (weight_lbs: number, height_in: number, age_yrs: number, sex: Sexes | string) => {
  const kg_per_pound = 0.453592;
  const cm_per_inch = 2.54;
  let bmr;
  if (sex === 'male') {
    bmr = 88.362 + ( 13.397 * weight_lbs * kg_per_pound ) + ( 4.799 * height_in * cm_per_inch ) - (5.677 * age_yrs);
  } else if (sex === 'female') {
    bmr = 447.593 + ( 9.247 * weight_lbs * kg_per_pound ) + ( 3.098 * height_in * cm_per_inch) - (4.33 * age_yrs);
  } else {
    throw new Error("Undefined sex");
  }

  return bmr;
}

const calcUserBMR = (userData: UserData, weight: number | undefined) => {
  const weight_lbs = weight || 0; // WeightStringToNum(weightString);
  const kg_per_pound = 0.453592;
  const cm_per_inch = 2.54;
  const height_in = userData.height_in || 0;
  const gender = userData.gender;
  const age_yrs = calcAge(userData.birthday);

  let bmr;
  if (gender === 'male') {
    bmr = 88.362 + ( 13.397 * weight_lbs * kg_per_pound ) + ( 4.799 * height_in * cm_per_inch ) - (5.677 * age_yrs);
  } else {
    bmr = 447.593 + ( 9.247 * weight_lbs * kg_per_pound ) + ( 3.098 * height_in * cm_per_inch) - (4.33 * age_yrs);
  }
  return Math.floor(bmr);
}

const calculateAdjustedCalorieMax = (bmr: number, weight: number | undefined, userWeightGoal: number | undefined, userData: UserData) => {
  // const weight = WeightStringToNum(weightString);
  // const goal = WeightStringToNum(userWeightGoal);
  const weightDiff = (weight || 0) - (userWeightGoal || 0);
  const caloriesDiff = weightDiff * caloriesPerPound;
  const caloriesPerDayDiff = caloriesDiff / calcDaysLeft(userData.goal_end_date);
  const adjustedCalorieMax = bmr - caloriesPerDayDiff;
  return Math.floor(adjustedCalorieMax);
}

const CalcBMR = (weight: number | undefined, userData: UserData) => calcUserBMR(userData, weight);

const CalcCalorieMax = (weight: number | undefined, userWeightGoal: number | undefined, userData: UserData) => calculateAdjustedCalorieMax(calcUserBMR(userData, weight), weight, userWeightGoal, userData);

const interpolateWeightsByDates = (d1: WeightMeasurement, d2: WeightMeasurement) => {

  // Linear interpolation of weight values between two dates
  let weightArray: WeightMeasurement[] = []; // Return object
  const momentFormat = "YYYY-MM-DD";

  // Check valid arguments first
  let m1 = moment(d1.date, momentFormat);
  let m2 = moment(d2.date, momentFormat);
  let v1 = d1.value;
  let v2 = d2.value;
  if (!d1 || !d2 || !v1 || !v2 || !m1 || !m2) {
    console.error("WARNING: Interpolation not possible between ",d1," and ",d2);
    return weightArray;
  }

  // Check if dates are misaligned or not
  if (moment(m1).add(1, "days").format(momentFormat) === moment(m2).format(momentFormat)) return weightArray;

  // console.log("Proceeding ... ");
  // console.log("d1: ",d1);
  // console.log("d2: ",d2);

  // Proceed to interpolation
  const deltaT = m2.diff(m1, "days"); // Day difference (non inclusive of start or end indexes)
  let dateArray = Array(deltaT).fill(null);
  let lastV = v1;
  dateArray.forEach((d, i) => {
    if (i+1 !== dateArray.length) {
      const nextVal = lastV + ((v2 - lastV) / (deltaT - i + 1));
      weightArray = [...weightArray, {
        date: moment(m1).add(i + 1, "days").format(momentFormat),
        value: nextVal,
        interpolated: true,
      }];
      lastV = nextVal;
    }
  });

  // console.log("weightArray: ",weightArray);

  return weightArray;
}

const interpolateWeightLogHistory = (weightLogHistory: WeightMeasurement[]) => {

  // console.log("weightLogHistory: ",weightLogHistory);

  if (!weightLogHistory) return [];
  if (weightLogHistory.length < 2) return weightLogHistory;
  
  let sortedWeightLogHistory = [ ...weightLogHistory ];
  // sort(sortedWeightLogHistory, "date");

  // console.log("sortedWeightLogHistory: ",sortedWeightLogHistory.map(d => d.date));

  let interpolatedWeightLog: WeightMeasurement[] = [];
  let last: WeightMeasurement;

  sortedWeightLogHistory.forEach((h, i) => {

    if (i > 0) {
      // Add interpolation values if needed
      const interpolatedValues = interpolateWeightsByDates(last, h);
      if (interpolatedValues.length) {
        // console.log("Interpolated between ",last.date," and ",h.date);
        // console.log("interpolatedValues: ",interpolatedValues);
        interpolatedWeightLog.push(...interpolatedValues);
      }
    }

    // Push last value
    last = h;
    interpolatedWeightLog.push(h);
  });

  const sma_window = 7;
  interpolatedWeightLog.forEach((h, i) => {
    const lookback_vals = interpolatedWeightLog.slice(Math.max(i + 1 - sma_window, 0), i+1);
    if (lookback_vals.length) {
      const trueWeight = lookback_vals.reduce(function (acc, obj) { return acc + obj.value; }, 0) / lookback_vals.length;
      interpolatedWeightLog[i] = { 
        ...interpolatedWeightLog[i],
        trueWeight: trueWeight
      }
    }
  });

  // sort(interpolatedWeightLog, "date");

  // console.log("interpolatedWeightLog: ",interpolatedWeightLog);

  return interpolatedWeightLog;
}

export { calcBMRMSJ, CalcBMR, CalcCalorieMax, interpolateWeightsByDates, interpolateWeightLogHistory };