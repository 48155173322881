import {
  Flex, Spinner, Stack, Text
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
// import { SmallCloseIcon } from '@chakra-ui/icons';

// import { UseUserContext } from "../context/UserContext";
import OnboardingStartPage from './OnboardingStartPage';
import OnboardingHeightPage from './OnboardingHeightPage';
import OnboardingSexPage from './OnboardingSexPage';
import OnboardingBirthdayPage from './OnboardingBirthdayPage';
import OnboardingWeightPage from './OnboardingWeightPage';
import OnboardingGoalPage from './OnboardingGoalPage';
import OnboardingConfirmPage from './OnboardingConfirmPage';
import OnboardingPhonePage from './OnboardingPhonePage';
import OnboardingPlanPage from './OnboardingPlanPage';
import OnboardingPayment from '../../components/OnboardingPayment';

export default function Onboarding(): JSX.Element {

  // const { currentUser, updateCurrentUserData } = UseUserContext();
  // const userName = currentUser.data.name || currentUser.auth.email;
  // const userPlan = "Free Account";

  const [onboardingStage, setOnboardingStage] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const incrementOnboardingStage = () => {
    setOnboardingStage(onboardingStage + 1);
    window.scrollTo(0, 0);
  }

  const decrementOnboardingStage = () => {
    setOnboardingStage(Math.max(onboardingStage - 1,0));
    window.scrollTo(0, 0);
  }

  const renderOnboardingStage = () => { 
    switch (onboardingStage) {
      case 0:
        return <OnboardingStartPage onAdvance={incrementOnboardingStage} onRetreat={decrementOnboardingStage} />;
      case 1:
        return <OnboardingHeightPage onAdvance={incrementOnboardingStage} onRetreat={decrementOnboardingStage} />;
      case 2:
        return <OnboardingSexPage onAdvance={incrementOnboardingStage} onRetreat={decrementOnboardingStage} />;
      case 3:
        return <OnboardingBirthdayPage onAdvance={incrementOnboardingStage} onRetreat={decrementOnboardingStage} />;
      case 4:
        return <OnboardingWeightPage onAdvance={incrementOnboardingStage} onRetreat={decrementOnboardingStage} />;
      case 5:
        return <OnboardingGoalPage onAdvance={incrementOnboardingStage} onRetreat={decrementOnboardingStage} />;
      case 6:
        return <OnboardingPhonePage onAdvance={incrementOnboardingStage} onRetreat={decrementOnboardingStage} />;
      // case 7:
        // return <OnboardingConfirmPage onAdvance={incrementOnboardingStage} onRetreat={decrementOnboardingStage} />;
      case 7:
        return <OnboardingPlanPage onAdvance={incrementOnboardingStage} onRetreat={decrementOnboardingStage} />;
      case 8:
        return <Stack align={'center'}>
        <Text fontSize={'lg'} color={'gray.600'}>Loading...</Text>
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='green.500'
          size='xl'
        />
      </Stack>;
        // return <OnboardingPayment />;
      case 10:
        return <div>Loading next steps ...</div>; // Compare to WW pricing plans
      // case 7:
        // return <OnboardingGoal2 onAdvance={incrementOnboardingStage} />;
      default:
        return <div>Error invalid onboardingStage {onboardingStage}</div>;
    }
  }

  return (
    <Flex
      minH={'100vh'}
      minW={'100vw'}
      maxW={'100vw'}
      align={'center'}
      justify={'center'}
      style={{overflow: 'hidden'}}
      // bg={useColorModeValue('gray.50', 'gray.800')}
    >
      {renderOnboardingStage()}
    </Flex>
  );
}