import { useEffect } from "react";
import {
  Button,
  Text,
} from '@chakra-ui/react';
import { Link } from "react-router-dom";

import "firebaseui/dist/firebaseui.css";

import { auth } from '../firebase/firebase';
import { UseUserContext } from "../context/UserContext";
import { onAuthStateChanged } from "firebase/auth";

import Login from "./Login";

function UserSwitcher () {

  const { currentUser, setCurrentUser } = UseUserContext();

  async function getCustomClaimRole() {
    await auth.currentUser?.getIdToken(true);
    const decodedToken = await auth.currentUser?.getIdTokenResult();
    if (!decodedToken) return null;
    return decodedToken.claims.stripeRole;
  }

  const signOut = () => {
    auth.signOut();
    setCurrentUser({});
  }

  useEffect(() => {
    const unregisterAuthObserver = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User

        // Also check auth token claims
        const claims = await getCustomClaimRole();
        setCurrentUser({ uid: user.uid, email: user.email, claims });
      } else {
        // User is signed out
        setCurrentUser({});
      }
    })
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, [auth]);

  return (
    <>
      { currentUser.auth!.uid ? (
        <>
          <Text 
            fontSize='xl' 
            align={"center"}
          >
            Signed in as <strong>{currentUser.auth!.email}</strong>
          </Text>
          <Link to="/dashboard">
            <Button
              colorScheme="green"
              w="full"
            >
              Continue
            </Button>
          </Link>
          <Button
            onClick={() => signOut()}
            colorScheme="green"
            variant="outline"
            >
            Sign out
          </Button>
        </>
      ) : (
        <>
          {/* <h2>This creates an account if you don't have one already</h2> */}
          <Login />
        </>
      ) }
    </>
  );
}

export default UserSwitcher;