import React, { useState } from 'react';
import moment from 'moment';
import {
  Button,
  // FormControl,
  FormLabel,
  Input,
  Stack,
  // Avatar,
  // AvatarBadge,
  Radio, 
  RadioGroup,
  Text,
  InputGroup,
  InputRightAddon,
} from '@chakra-ui/react';
// import { SmallCloseIcon } from '@chakra-ui/icons';
import PhoneInput from 'react-phone-number-input';
import { Value as E164Number } from 'react-phone-number-input';

// import UserGravatar from './UserGravatar';
import { UseUserContext } from "../context/UserContext";
import { isValidBirthday, isValidHeight, isValidPhoneNumber, isValidProfileUpdate, isValidSex } from '../types/UserDataValidations';
import 'react-phone-number-input/style.css';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebase/firebase';

export default function ProfileForm(): JSX.Element {

  const { currentUser, updateCurrentUserData } = UseUserContext();
  // const userName = currentUser.data.name || currentUser.auth.email;
  // const userPlan = "Free Account";

  const [userSex, setUserSex] = useState(String(currentUser.data.gender || ""));
  const [userBirthday, setUserBirthday] = useState(String(currentUser.data.birthday || ""));
  const [userHeight, setUserHeight] = useState(String(currentUser.data.height_in || ""));
  const [userPhoneNumber, setUserPhoneNumber] = useState(String(currentUser.data.phoneNumber || ""));

  const [userHeightFeet, setUserHeightFeet] = useState(String(Math.floor(Number(userHeight)/12) || ""));
  const [userHeightInches, setUserHeightInches] = useState(String(Math.round(Number(userHeight) - 12 * Math.floor(Number(userHeight)/12)) || ""));

  const validSex = isValidSex(userSex);
  const validBirthday = isValidBirthday(userBirthday);
  const validHeight = isValidHeight(userHeight);
  const validPhoneNumber = isValidPhoneNumber(userPhoneNumber);
  const validProfileUpdate = isValidProfileUpdate(userSex, userBirthday, userHeight, userPhoneNumber);

  const maxBirthday = moment().subtract(18, "years").format("YYYY-MM-DD");

  // const setUserHeightHumanReadable = (height: string) => {
  //   const userHeightFeet = Number(height)/12;
  //   const userHeightInches = Math.round(Number(height) - 12 * Math.floor(Number(height)/12));
  //   setUserHeightFeet(userHeightFeet);
  //   setUserHeightInches(userHeightInches);
  // }

  const inputUserHeightFeet = (height: string) => {
    const minHeight = String(Math.round(Math.max(Number(height), 0.00)) || "");
    setUserHeightFeet(minHeight);
    setUserHeight(String((Number(minHeight)*12 + Number(userHeightInches)) || ""));
  }
  const inputUserHeightInches = (height: string) => {
    const maxHeight = String(Math.round(Math.min(Number(height), 12)) || "");
    const minHeight = String(Math.max(Number(maxHeight), 0.00) || "");
    setUserHeightInches(minHeight);
    setUserHeight(String((Number(userHeightFeet)*12 + Number(minHeight)) || ""));
  }

  const setPhoneNumber = (num?: E164Number | undefined) => {
    if (!num) return;
    setUserPhoneNumber(num);
  }

  const submitUpdateProfile = async () => {
    if (validProfileUpdate) {
      console.log("Submitting profile update: ")
      const newProfile = {
        gender: userSex,
        birthday: userBirthday,
        height_in: Number(userHeight),
        phoneNumber: userPhoneNumber,
      }
      // console.log(newProfile);
      await updateCurrentUserData(currentUser.auth.uid!, newProfile);
      // console.log("Updated profile!: ",currentUser.data);
      if (!currentUser.data.phoneNumber) {
        console.log("Completing onboarding profile for the first time");
        logEvent(analytics, 'profile_onboarded_initial', {
          uid: currentUser.auth.uid
        });
      } else {
        logEvent(analytics, 'profile_updated', {
          uid: currentUser.auth.uid
        });
      }
    }
  }

  const handleChange = (event: any, f: any) => { 
    event.preventDefault();
    return(f(event.target.value));
  };

  return (
    <React.Fragment>
        
        {/* <Alert status='info'>
          <AlertIcon />
          We're currently in Alpha - you'll need to contact us to update your profile
        </Alert> */}

        {/* <FormControl id="userName">
          <FormLabel>Profile icon</FormLabel>
          <Stack direction={['column', 'row']} spacing={6}>
            <Center>
              <Avatar size="lg" src={UserGravatar()}> */}
                {/* <AvatarBadge
                  as={IconButton}
                  size="sm"
                  rounded="full"
                  top="-10px"
                  colorScheme="red"
                  aria-label="remove Image"
                  icon={<SmallCloseIcon />}
                /> */}
              {/* </Avatar>
            </Center> */}
            {/* <Center w="full">
              <Button w="full">Change Icon</Button>
            </Center> */}
          {/* </Stack>
        </FormControl> */}
        {/* <FormControl id="userName" isRequired> */}
          {/* <FormLabel>Profile name</FormLabel>
          <Input
            placeholder="UserName"
            _placeholder={{ color: 'gray.500' }}
            type="text"
            isReadOnly
            value={String(userName)}
            disabled
          /> */}
        {/* </FormControl> */}
        {/* <FormControl id="userName" isRequired> */}
        {/* <FormLabel>Profile plan</FormLabel>
          <Input
            placeholder="UserPlane"
            _placeholder={{ color: 'gray.500' }}
            type="text"
            isReadOnly
            value={userPlan}
            disabled
          /> */}
        {/* </FormControl> */}
        {/* <FormControl id="email" isRequired> */}
          <FormLabel>Email Address</FormLabel>
          <Input
            placeholder="your-email@example.com"
            _placeholder={{ color: 'gray.500' }}
            type="email"
            isReadOnly
            value={String(currentUser.auth.email)}
            disabled
          />
        {/* </FormControl> */}
        {/* <FormControl id="password" isRequired>
          <FormLabel>Password</FormLabel>
          <Input
            placeholder="password"
            _placeholder={{ color: 'gray.500' }}
            type="password"
          />
        </FormControl> */}
         {/* <FormControl id="userHeight" isRequired> */}
         <FormLabel>Mobile Phone Number</FormLabel>
          { !validPhoneNumber  && <Text mb='8px' color={"red"}> phone number including the country code (e.g. +13609894787)</Text> }
          {/* <Input
            isInvalid={!validPhoneNumber}
            placeholder="Phone number"
            _placeholder={{ color: 'gray.500' }}
            type="tel"
            onChange={(event) => handleChange(event, setUserPhoneNumber)} 
            value={userPhoneNumber}
            // isReadOnly
            // disabled
          /> */}
          <PhoneInput
            // isinvalid={!validPhoneNumber}
            defaultCountry="US"
            placeholder="Phone number"
            _placeholder={{ color: 'gray.500' }}
            type="tel"
            onChange={setPhoneNumber} 
            value={userPhoneNumber}
            // isReadOnly
            // disabled
          />
        {/* </FormControl> */}
        {/* <FormControl id="userGender" isRequired> */}
          <FormLabel>Physical Sex <i>(for BMR Calculation)</i></FormLabel>
          {/* <Input
            placeholder="UserGender"
            _placeholder={{ color: 'gray.500' }}
            type="text"
            value={String(currentUser.data.gender)}
            isReadOnly
            disabled
          /> */}

          <RadioGroup onChange={setUserSex} value={userSex}>
            { !validSex  && <Text mb='8px' color={"red"}>Please pick a sex</Text> }
            <Stack direction="row">
              <Radio value="male">Male</Radio>
              <Radio value="female">Female</Radio>
            </Stack>
          </RadioGroup>

        {/* </FormControl> */}
        {/* <FormControl id="userBirthday" isRequired> */}
          <FormLabel>Birthday <i>(for BMR Calculation)</i></FormLabel>
          { !validBirthday  && <Text mb='8px' color={"red"}>Please enter your birthday in mm/dd/yyyy format </Text> }
          <Input
            isInvalid={!validBirthday}
            placeholder="UserBirthday"
            _placeholder={{ color: 'gray.500' }}
            max={maxBirthday}
            type="date"
            onChange={(event) => handleChange(event, setUserBirthday)} 
            value={userBirthday}
            // isReadOnly
            // disabled
          />
        {/* </FormControl> */}
        {/* <FormControl id="userHeight" isRequired> */}
          <FormLabel>Height <i>(for BMR Calculation)</i></FormLabel>
          { !!validHeight  && <Text>Looks like you are {userHeightFeet}' {userHeightInches ? ` ${userHeightInches}"` : ""} tall</Text> }
          { !validHeight  && <Text mb='8px' color={"red"}>Please enter your height in inches</Text> }
          {/* <Input
            isInvalid={!validHeight}
            placeholder="Height (in inches)"
            _placeholder={{ color: 'gray.500' }}
            type="number"
            onChange={(event) => handleChange(event, setUserHeight)} 
            value={userHeight}
            // isReadOnly
            // disabled
          /> */}
          <InputGroup>
            <Input
              isInvalid={!validHeight}
              placeholder="Height (in feet)"
              _placeholder={{ color: 'gray.500' }}
              type="number"
              onChange={(event) => handleChange(event, inputUserHeightFeet)} 
              value={userHeightFeet}
              // isReadOnly
              // disabled
            />
            <InputRightAddon children='feet' />
          </InputGroup>
          <InputGroup>
            <Input
              isInvalid={!validHeight}
              placeholder="Height (in inches)"
              _placeholder={{ color: 'gray.500' }}
              type="number"
              onChange={(event) => handleChange(event, inputUserHeightInches)} 
              value={userHeightInches}
              // isReadOnly
              // disabled
            />
            <InputRightAddon children='inches' />
          </InputGroup>
        {/* </FormControl> */}
        
        <Stack spacing={6} direction={['column', 'row']}>
          {/* <Button
            bg={'red.400'}
            color={'white'}
            w="full"
            _hover={{
              bg: 'red.500',
            }}>
            Cancel
          </Button> */}
          <Button
            bg={'green.400'}
            color={'white'}
            w="full"
            disabled={!validProfileUpdate}
            onClick={submitUpdateProfile}
            _hover={{
              bg: 'green.500',
            }}>
            Save Changes
          </Button>
        </Stack>
    </React.Fragment>
  );
}