
import {
  Button,
  Heading,
  Stack,
  Text,
  Alert,
  AlertIcon,
  AlertDescription,
  AlertTitle
} from '@chakra-ui/react';

import LogoImage from './LogoImage';
import { analytics } from '../firebase/firebase';
import { UseUserContext } from '../context/UserContext';
import { useState } from 'react';
import { logEvent } from 'firebase/analytics';

export default function OnboardingThankyou(): JSX.Element {

  const { currentUser, updateCurrentUserData } = UseUserContext();

  const logPaywallPassed = async () => {

    console.log("Logging paywall passed");

    setLoading(true);
    const newProfileAttributes = {
      paywallPassed: true,
    }
    await updateCurrentUserData(currentUser.auth.uid!, newProfileAttributes);
    logEvent(analytics, 'checkout_success', {
      uid: currentUser.auth.uid
    });
    setLoading(false);
  }

  const [isLoading, setLoading] = useState(false);

  return (
    <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
      <Stack align={'left'}>
        <LogoImage boxSize="xs"/>
        <Heading textAlign={"left"} fontSize={'4xl'}>Activate your Account</Heading>
        <Text fontSize={'lg'} color={'gray.600'}>
          Hey, thanks for signing up! Before you proceed, you might want to do this:
        </Text>

        <Button 
            // colorScheme="green"
            // variant={"ghost"}
            onClick={() => window.open('/assets/basal-ai.vcf')}
            marginBottom="2"
          >Add Basal AI to Your Contacts
          </Button>

          <Alert
            status='info'
            variant="subtle"
            colorScheme={"green"}
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            textAlign='center'
            // height='200px'
          >
            <AlertIcon boxSize='40px' mr={0} />
            <AlertTitle mt={4} mb={1} fontSize='lg'>
              Getting Started?
            </AlertTitle>
            <AlertDescription>
              First, add Basal AI to your contacts, then text us "I weigh xxx" at +1 (737) 377-3306 to update your progress and estimates.
            </AlertDescription>
          </Alert>
      </Stack>

      <Button 
      colorScheme="green"
      onClick={logPaywallPassed} disabled={isLoading} isLoading={isLoading}>Activate Account</Button>

      <Text>If you have any questions please reach out to us at <strong><a href="mailto:hello@basal.ai">hello@basal.ai</a></strong></Text>

    </Stack>
  );
}