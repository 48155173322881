import React from "react";
import "firebaseui/dist/firebaseui.css";

// import { auth } from "../firebase/firebase";
import { UseUserContext } from "../context/UserContext";
import AuthPage from "../pages/AuthPage";
import { isValidProfileUpdate } from "../types/UserDataValidations";
// import OnboardingBasic from "./OnboardingBasic";
import OnboardingController from "../pages/Onboarding/_OnboardingController";
import OnboardingBMR from "./OnboardingBMR";
import OnboardingGoal from "./OnboardingGoal";
import OnboardingPayment2 from "./OnboardingPayment2";
import OnboardingThankyou from "./OnboardingThankyou";
import OnboardingPlan from "../pages/Onboarding/OnboardingPlanPage";

type Props = {
  children?: React.ReactNode;
}

function AuthGate({ children }: Props) {
  const { currentUser } = UseUserContext();
  
  // Check auth status, else render auth form
  if (!currentUser.auth.uid) return <AuthPage />;

  // Check onboarding status, else render onboarding
  const userData = currentUser.data;

  // -- Basic onboarding data
  const isValidOnboardBasic = isValidProfileUpdate(String(userData.gender), String(userData.birthday), String(userData.height_in), 
    //"+13609894787" 
    String(userData.phoneNumber)
  );
  if (!isValidOnboardBasic || !userData.goal_end_date ) return <OnboardingController />;

  // -- Get weight and first BMR calculation
  // if (!userData.last_weight_lbs) return <OnboardingBMR />;

  // -- Check that a goal is set
  // if (!userData.goal_end_date || !userData.goal_weight_lbs) return <OnboardingGoal />;

  // -- Check that a subscription is created
  if (!currentUser.data?.overridePaywall && !(currentUser.auth?.claims === "premium" || currentUser.auth?.claims?.includes("premium")) ) return <OnboardingPayment2 />;
  // if (!currentUser.data?.overridePaywall && !currentUser.data?.freeTrialStarted && !(currentUser.auth?.claims === "premium" || currentUser.auth?.claims?.includes("premium")) ) return <OnboardingController />;

  // -- Check that a paywall thankyou is visited for event logging purposes
  if (!(currentUser.data.paywallPassed)) return <OnboardingThankyou />;

  // Render children unobstructed
  return (
    <>
    { children }
    </>
  );
}

export default AuthGate;