import {
  Flex,
  Stack,
  Heading,
  Text,
} from '@chakra-ui/react';
import LogoImage from '../components/LogoImage';

import UserSwitcher from '../components/UserSwitcher';
import { UseUserContext } from '../context/UserContext';

export default function AuthPage() {
  const { currentUser } = UseUserContext();

  const isLoggedIn = !!currentUser.auth.uid;

  return (
    <Flex
      minH={'90vh'}
      align={'center'}
      justify={'center'}
    >
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <LogoImage boxSize="xs"/>
          <Heading textAlign={"center"} fontSize={'4xl'}>{ !isLoggedIn ? "Sign up for Basal AI" : "Welcome to Basal AI" }</Heading>
          <Text fontSize={'lg'} color={'gray.600'} align="center">
            { !isLoggedIn ? "Get started on your metabolic weight loss journey ✌️" : "Well hello there! Let's get cracking ✌️" }
          </Text>
        </Stack>
        <Stack spacing={4}>
          <UserSwitcher />
          { !isLoggedIn && (
            <Text fontSize={'md'} color={'gray.600'} align="center">
              Whether you're new to Basal or not, just hit that button for us and we'll take care of the rest.
            </Text>
          )}
        </Stack>
      </Stack>
    </Flex>
  );
}