import React, { useState } from "react";
import {
  Button,
  Text,
  FormLabel,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
} from '@chakra-ui/react';
import moment from "moment";

import { UseUserContext } from '../context/UserContext';
import WeightInput from "./WeightInput";
import DateInput from "./DateInput";
import { CalcBMR, CalcCalorieMax } from "../bmr/bmr";
import { sort } from "../utils/utils";
import { interpolateWeightLogHistory } from "../bmr/bmr";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase/firebase";

export default function GoalForm(): JSX.Element {

  const { currentUser, setTempUserData, tempUserData, updateCurrentUserData } = UseUserContext();
  const userData = tempUserData || currentUser.data;

  const userWeightLogRaw = currentUser.weight_log || { };
  let userWeightLog = userWeightLogRaw;
  if (userWeightLog.history) {
    sort(userWeightLog.history, "date");
  }
  const userWeightLogHistory = interpolateWeightLogHistory(userWeightLog.history || []);
  const lastLog = userWeightLogHistory.slice(userWeightLogHistory.length-1, userWeightLogHistory.length)[0];
  const trueWeight = lastLog.trueWeight;

  const [ measuredWeight, setMeasuredWeight ] = useState(String(userData.last_weight_lbs || ""));
  const [ userWeightGoal, setUserWeightGoal ] = useState(String(Number(userData.goal_weight_lbs) || ""));
  const [ userGoalDate, setUserGoalDate ] = useState(String(userData.goal_end_date || ""));
  const userGoalDateMoment = moment(userGoalDate);
  const isValidWeight = Number(userWeightGoal) > 5;

  // TBD use true weight here

  const userBMR = CalcBMR(Number(trueWeight || measuredWeight), userData);

  const userCalorieMax = CalcCalorieMax(Number(trueWeight || measuredWeight), Number(userWeightGoal), userData);

  // console.log("userData: ",userData);
  // console.log(`userCalorieMax: ${userCalorieMax} for weight ${measuredWeight} and goal ${userWeightGoal}`);

  const calorieDelta = userBMR - Math.max(userCalorieMax,0);
  const poundsPerDay = Math.abs(calorieDelta/3500);
  const poundsPerWeek = poundsPerDay * 7;
  const weightWarning = poundsPerWeek > 2;
  const validGoalDate = !!moment(userGoalDate).isAfter(moment());

  let validCalorieDelta = false;
  if (!isNaN(calorieDelta)) validCalorieDelta = true;

  const onSaveChanges = () => {
    updateCurrentUserData(currentUser.auth.uid!, tempUserData || currentUser.data);
    if (!currentUser.data.goal_weight_lbs) {
      console.log("Completing goal setting for the first time");
      logEvent(analytics, 'profile_onboarded_goal', {
        uid: currentUser.auth.uid,
        currentWeight: Number(measuredWeight),
        goalWeight: Number(userWeightGoal),
        goalDate: userGoalDate
      });
    } else {
      logEvent(analytics, 'goal_updated', {
        uid: currentUser.auth.uid,
        currentWeight: Number(measuredWeight),
        goalWeight: Number(userWeightGoal),
        goalDate: userGoalDate
      });
    }
  }

  const onSetWeightGoal = (newVal: string, db_field: string) => {
    setTempUserData({ [db_field]: Number(newVal) });
    setUserWeightGoal(newVal);
  }

  const onSetGoalDate = (newVal: string, db_field: string) => {
    setTempUserData({ [db_field]: newVal });
    setUserGoalDate(newVal);
  }

  return (
    <React.Fragment>
      
      <FormLabel>Target weight (lbs)</FormLabel>
      { !isValidWeight && <Text color={"red"}>Please enter a valid weight (in pounds)</Text>}
      {WeightInput(userWeightGoal, onSetWeightGoal, "goal_weight_lbs", "Enter your weight goal (in pounds)")}

      <FormLabel>Target completion date</FormLabel>
      {DateInput(userGoalDate, onSetGoalDate, "goal_end_date", "Enter your target goal completion date (YYYY-MM-DD)")}

      <Text fontSize={'sm'} color={'gray.600'} align="center">Your goal is to hit <strong>{userWeightGoal}</strong> lbs by <strong>{userGoalDateMoment.format("MMMM Do YYYY")}</strong></Text>

      <Stat>
        <StatLabel>Today's Calorie Max</StatLabel>
        <StatNumber>{Math.max(userCalorieMax,0).toLocaleString()} Calories</StatNumber>
        <StatHelpText>
          This is your daily Calorie intake budget to stay on track for your goal.
        </StatHelpText>
      </Stat>

      <Text fontSize={'sm'} color={'gray.600'}>{ `Your normal base BMR is ${userBMR} Calories, which means that based on your current weight - in order to hit your goal you'll need to stick to a maximum of ${Math.max(userCalorieMax,0)} Calories today. ` }<strong>Remember, this will update each day based on progress and model adjustments.</strong></Text>
      
      <Stat>
        <StatLabel>Estimated Daily Calories { calorieDelta >= 0 ? "Burned" : "Stored" }</StatLabel>
        <StatNumber>{(Math.abs(calorieDelta)).toLocaleString()} Calories {calorieDelta >= 0 ? "Burned" : "Stored"}</StatNumber>
        <StatHelpText>
          ~ {poundsPerDay.toLocaleString()} lbs {calorieDelta >= 0 ? "burned" : "stored"} per day, {poundsPerWeek.toLocaleString()} lbs per week.
        </StatHelpText>
      </Stat>

      { !!weightWarning && <Text fontSize={'sm'} color={'red'}>Hey, this exceeds the general guidelines of {weightWarning} pound per week. Weight changes in excess of 2 pounds per week is generally dangerous. We cap our models at {poundsPerWeek.toFixed(2)} pounds per week maximums for safety reasons.</Text>} 

      <Text fontSize={'sm'} color={'gray.600'}>If you outperform our model, you'll find your Calorie budget increasing, and/or will lose weight fast than expected. The reverse also holds true if you underperform.</Text>

      { !!validGoalDate ? (
        <Text>Looks like your goal is coming up about <strong>{userGoalDateMoment.fromNow(true)}</strong> from now. You ready for this?</Text>
      ) : (
        <Text fontSize={'sm'} color={'red'}>Hey, please double check your goal date. It looks like it isn't entered properly or is set in the past.</Text>
      )}

      { !validCalorieDelta && <Text fontSize={'sm'} color={'red'}>Hmmm, looks like your daily Calorie max doesn't make sense. Please double-check your inputs.</Text>} 

      <Button
        bg={'green.400'}
        color={'white'}
        w="full"
        _hover={{
          bg: 'green.500',
        }}
        onClick={onSaveChanges}
        disabled={!isValidWeight || !!weightWarning || !validGoalDate || !validCalorieDelta}
      >
        I'm So Ready
      </Button>

    </React.Fragment>
  );
}