import React from "react";
import "firebaseui/dist/firebaseui.css";

import { UserContextProvider } from "../context/UserContext";

type Props = {
  children?: React.ReactNode;
}

// Please note that you can't use useContext in the same component where you use the
// Context provider because useContext gets the Context from its nearest parent.
// https://felixgerschau.com/react-typescript-context/

function AuthProvider ({ children }: Props) {
  return (
    <UserContextProvider>
      { children }
    </UserContextProvider>
  );
}

export default AuthProvider;