
import {
  Button,
  Heading,
  List,
  ListIcon,
  ListItem,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
} from '@chakra-ui/react';
// import { getStripePayments, createCheckoutSession, onCurrentUserSubscriptionUpdate } from "@stripe/firestore-stripe-payments";
import { 
  collection, addDoc, onSnapshot,
} from 'firebase/firestore';

import LogoImage from './LogoImage';
import { analytics, db } from '../firebase/firebase';
import { UseUserContext } from '../context/UserContext';
import { useState } from 'react';
import { logEvent } from 'firebase/analytics';
import { CheckCircleIcon } from '@chakra-ui/icons';
// import GoalForm from "./GoalForm";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

export default function OnboardingPayment(): JSX.Element {

  const { currentUser } = UseUserContext();
  // const userData = tempUserData || currentUser.data;

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabsChange = (index: number) => {
    setTabIndex(index)
  }

  // currentUser.auth.uid

  const stripeCheckout = async (tabIndex: number) => {

    // Annual or monthly plan
    const priceId = tabIndex === 0 ? 'price_1N1zl5FxMgcRye3wluBPzEGy' : 'price_1N1zkoFxMgcRye3wOuHy050r';
    const isTrial = tabIndex === 0 ? true : false;

    console.log("Called stripeCheckout");

    // Create checkout session document
    try {
      setLoading(true);
      const checkout_session_ref = collection(db, `users/${currentUser.auth.uid}/checkout_sessions`);
      const checkoutDocRef = await addDoc(checkout_session_ref, {
        price: priceId,
        success_url: window.location.href,
        cancel_url: window.location.href,
        trial_from_plan: isTrial, // No support for trial_days unfortunately, so use legacy param
      });
      console.log("Created checkoutDocRef: ",checkoutDocRef);

      // Listen to checkout doc snapshot
      const unsub = onSnapshot(checkoutDocRef, (snap) => {
        console.log("Called onSnapshot with snap: ",snap);
        const data = snap.data();
        console.log("Snapshot has data: ",data);
        if (data) {
          console.log("Current data: ", data);
          const { error, url } = data;
          if (error) {
            // Show an error to your customer and
            // inspect your Cloud Function logs in the Firebase console.
            alert(`An error occured: ${error.message}`);
          }
          if (url) {
            // We have a Stripe Checkout URL, let's redirect.
            console.log("Redirecting to url: ",url);
            setLoading(false);
            logEvent(analytics, 'checkout_initiated', {
              uid: currentUser.auth.uid
            });
            window.location.assign(url);
          }
        } else {
          setLoading(false);
          // Something went wrong
          console.error("Did not expect this to be triggered");
        }
      });
    } catch (e) {
      console.error("Failed to create a checkout session: ",e);
      logEvent(analytics, 'checkout_init_failure', {
        uid: currentUser.auth.uid
      });
      setLoading(false);
    }

    // Create checkout session
    // const session = await createCheckoutSession(payments, {
    //   price: "price_1MIe4QFxMgcRye3wPKK0SQcq",
    //   success_url: window.location.origin, // "https://example.com/payments/success",
    //   cancel_url: window.location.origin, // "https://example.com/payments/cancel",
    //   trial_period_days: 7,
    // });
    // window.location.assign(session.url);

    // onCurrentUserSubscriptionUpdate(
    //   payments,
    //   (snapshot) => {
    //     for (const change in snapshot.changes) {
    //       if (change.type === "added") {
    //         console.log(`New subscription added with ID: ${change.subscription.id}`);
    //       }
    //     }
    //   }
    // );
  }

  const [isLoading, setLoading] = useState(false);

  return (
    <Stack spacing={8} mx={'auto'} maxW={'xl'} py={12} px={6} >
      <Stack align={'left'}>

        <LogoImage boxSize="xs"/>
        <Heading textAlign={"left"} fontSize={'4xl'}>Activate your Account</Heading>
        <Text fontSize={'lg'} color={'gray.600'}>
          Activate your account now and choose the annual billing option for a free 7 day trial, during which we will tune your BMR algorithm for maximum accuracy. Monthly plans are also available.
        </Text>
      </Stack>

      <Tabs index={tabIndex} onChange={handleTabsChange} variant='soft-rounded' colorScheme='green'>
        <TabList>
          <Tab>Annual Plan (trial + save $160)</Tab>
          <Tab>Monthly Plan</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Text fontSize={"4xl"} fontWeight={"bold"}>$79.99</Text><Text fontSize={"4xs"} fontWeight={"medium"} color="gray">per year</Text>
            <Tag colorScheme='orange' my={4}>Free 7-day Trial</Tag>
          </TabPanel>
          <TabPanel>
            <Text fontSize={"4xl"} fontWeight={"bold"}>$19.99</Text><Text fontSize={"4xs"} fontWeight={"medium"} color="gray">per month</Text>
          </TabPanel>
        </TabPanels>
      </Tabs>

      {/* <stripe-pricing-table pricing-table-id="prctbl_1N5FwrFxMgcRye3wD3V0gKE0"
      publishable-key="pk_live_51MIcueFxMgcRye3w6oUBCBW3ob0xm1C4R0j3eiC67THF56UZixoTPWbIHgmC4Iv2bLqfTFlRJN8mp8fXgoYTiFMg00yx5OPf5O"
      client-reference-id={currentUser.auth.uid}>
      </stripe-pricing-table> */}

      <Text fontSize={'md'} color={'gray.800'}>
        With Basal.ai, you get instant access to:
      </Text>

      <List>
        <ListItem> <ListIcon as={CheckCircleIcon} color="green"/> Dynamically Updated Basal Metabolic Rate (BMR)</ListItem>
        <ListItem> <ListIcon as={CheckCircleIcon} color="green"/> Daily Calorie Max & Progress Statistics</ListItem>
        <ListItem> <ListIcon as={CheckCircleIcon} color="green"/> Basal AI True Weight Estimations</ListItem>
        <ListItem> <ListIcon as={CheckCircleIcon} color="green"/> Daily Reminders via Text Message (SMS)</ListItem>
        <ListItem> <ListIcon as={CheckCircleIcon} color="green"/> Progress Tracking and Logging via SMS or Web App</ListItem>
      </List>

      <Button 
        colorScheme={"green"}
        onClick={() => stripeCheckout(tabIndex)} 
        disabled={isLoading} 
        isLoading={isLoading}
      >{tabIndex === 0 ? "Start trial" : "Subscribe"}</Button>

      <Text fontSize={'sm'} color={'gray.600'}>
        <i>Why do we charge for Basal, you ask? </i>First, we have to pay cell providers to send SMS text messages. Second, we have found that paying for Basal AI dramatically increases your success rate. Lastly, we provide resources to perform the calculations on your own if you can't afford the subscription. <i>We strongly believe the forumula for weight loss should not be an industry secret.</i>
      </Text>

      <Text>If you have any questions please reach out to us at <strong><a href="mailto:hello@basal.ai">hello@basal.ai</a></strong></Text>

      {/* <GoalForm /> */}

    </Stack>
  );
}