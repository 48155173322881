import { useState } from "react";
import moment from "moment";
import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Stack,
  useColorModeValue,
  Container,
  Text,
  TableContainer,
  Table,
  Tr,
  Th,
  Td,
  Tbody,
  Thead,
  Switch,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Tag,
} from '@chakra-ui/react';
// import { SmallCloseIcon } from '@chakra-ui/icons';

import { interpolateWeightLogHistory } from "../bmr/bmr";
import WeightMeasurement from "../types/WeightMeasurement";
import { UseUserContext } from '../context/UserContext';
import WeightInput from "./WeightInput";
import DateInput from "./DateInput";
import WeightLogChart from "./WeightLogChart";
import { sort } from "../utils/utils";
import LogoImage from "./LogoImage";

export default function Progress(): JSX.Element {
  const { currentUser, setTempUserData, tempUserData, updateCurrentUserData } = UseUserContext();
  const userData = tempUserData || currentUser.data;
  const userWeightLogRaw = currentUser.weight_log || { };
  let userWeightLog = userWeightLogRaw;
  if (userWeightLog.history) {
    sort(userWeightLog.history, "date");
  }
  const userWeightLogHistory = interpolateWeightLogHistory(userWeightLog.history || []);
  const lastLog = userWeightLogHistory.slice(userWeightLogHistory.length-1, userWeightLogHistory.length)[0];
  const trueWeight = lastLog.trueWeight;

  const now = moment().format("YYYY-MM-DD");

  const [ measuredWeight, setMeasuredWeight ] = useState(String(userData.last_weight_lbs) || "");
  const [ measuredDate, setMeasuredDate ] = useState(now);
  const [ showInterpolated, setShowInterpolated ] = useState(true);

  const onSaveChanges = () => {
    updateCurrentUserData(currentUser.auth.uid!, tempUserData || currentUser.data, measuredDate);
  }

  const onSetWeight = (newVal: string, db_field: string) => {
    setTempUserData({ [db_field]: Number(newVal) });
    setMeasuredWeight(newVal);
  }

  const onSetMeasurementDate = (newVal: string, db_field?: string) => {
    setMeasuredDate(newVal);
  }

  // console.log("Rendering userWeightLogHistory: ",userWeightLogHistory)

  // const d1 = userWeightLogHistory[0];
  // const d2 = userWeightLogHistory[userWeightLogHistory.length-1];
  // const interpolatedWeights = interpolateWeightsByDates(d1, d2).reverse();
  // console.log(`interpolatedWeights d1(${d1.date}) d2(${d2.date}): ${interpolatedWeights.map(d => d.date)}`);

  // const interpolatedWeightLog = interpolateWeightLogHistory(userWeightLogHistory);
  // console.log("Interpolated from: ",interpolatedWeightLog," to: ",interpolatedWeightLog);

  return (

    <Container maxW={'3xl'}>

    {/* <Heading lineHeight={1.5} fontSize={{ base: '5xl', sm: '5xl' }}>
      Progress
    </Heading> */}

    <LogoImage boxSize="xs"/>
    <Heading fontSize={'4xl'}>Update Progress</Heading>

    <Text fontSize={'lg'} color={'gray.600'}>
      Please make sure this is accurate to allow us to dynamically update your <strong>Basal AI True Weight</strong> estimation with the greatest accuracy possible.
    </Text>

    { !!trueWeight && (
      <Stack
        spacing={4}
        w={'full'}
        maxW={'3xl'}
        bg={"white"}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={12}
      >
        <Stat>
          <StatLabel>Basal AI True Weight</StatLabel>
          <StatNumber>{trueWeight?.toFixed(2)} lbs</StatNumber>
          <StatHelpText>This measure will become more accurate over time as we gather more data</StatHelpText>
        </Stat>
      </Stack>
    )}

    <Stack
      spacing={4}
      w={'full'}
      maxW={'3xl'}
      bg={useColorModeValue('white', 'gray.700')}
      rounded={'xl'}
      boxShadow={'lg'}
      p={6}
      my={12}>

      <Heading lineHeight={1.1} fontSize={{ base: '2xl', sm: '3xl' }}>
        Progress Update
      </Heading>

      {/* <Alert status='info'>
        <AlertIcon />
        We're currently in Alpha - you'll need to contact us to save your progress
      </Alert> */}

      <FormLabel>Most recent weight (lbs)</FormLabel>
      { WeightInput(measuredWeight, onSetWeight, "last_weight_lbs", "Enter your most recently measured weight (in pounds)") }
      <FormLabel>Measurement date (update if not today)</FormLabel>
      { DateInput(measuredDate, onSetMeasurementDate, null, "Enter the date when this was measured (mm/dd/yyyy)") }
      
      <Stack spacing={6} direction={['column', 'row']}>
        {/* <Button
          bg={'red.400'}
          color={'white'}
          w="full"
          _hover={{
            bg: 'red.500',
          }}>
          Cancel
        </Button> */}
        <Button
          bg={'green.400'}
          color={'white'}
          w="full"
          _hover={{
            bg: 'green.500',
          }}
          onClick={onSaveChanges}
        >
          Submit Update
        </Button>
      </Stack>
    </Stack>

    <Stack
      spacing={4}
      w={'full'}
      maxW={'3xl'}
      bg={useColorModeValue('white', 'gray.700')}
      rounded={'xl'}
      boxShadow={'lg'}
      p={6}
      my={12}>

      <Heading lineHeight={1.1} fontSize={{ base: '2xl', sm: '3xl' }}>
        Historical Weight Log
      </Heading>

      { WeightLogChart(userWeightLogHistory, showInterpolated) }

      <FormControl display='flex' alignItems='center' onChange={() => setShowInterpolated(!showInterpolated) }>
        <FormLabel htmlFor='email-alerts' mb='0'>
          Show interpolated values?
        </FormLabel>
        <Switch id="showInterpolated" isChecked={showInterpolated} />
      </FormControl>

      { showInterpolated && <p style={{ color: "#3182ce" }}>Interpolated values shown</p> }

      <TableContainer>
        <Table size='sm'>
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th isNumeric>Weight (lbs)</Th>
              {/* { showInterpolated && <Th>Interpolated</Th> } */}
              <Th isNumeric>True Weight</Th>
            </Tr>
          </Thead>
          <Tbody>
            {
              [...userWeightLogHistory.filter(d => showInterpolated ? d : !d.interpolated)].reverse().map((d: WeightMeasurement) => (
              <Tr key={d.date}>
                <Td>{d.date}</Td>
                <Td isNumeric color={d.interpolated ? "#3182ce" : ""}>{(d.value).toFixed(2)}</Td>
                {/* { showInterpolated && <Td color={d.interpolated ? "#3182ce" : ""}>{d.interpolated ? "YES" : "NO"}</Td> } */}
                { d.trueWeight && <Td isNumeric color={"#38A169"}>{(d.trueWeight).toFixed(2)}</Td> }
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

    </Stack>
    </Container>
  );
}