// Full brownian motion simulation of weights based on user data
const RunWeightSimulation = (user_weight, user_target, user_t1) => {

  // const user_weight = 175;
  // const user_target = 165;
  const user_timeline = user_t1+1; // 90 + 1

  const NUM_SIMS = 1000;
  const PPW_MAX = 1.5;
  const AGE_INC = 1/365;
  const WTARGETINC = (user_target - user_weight)/user_timeline; // PPD
  const u_ppw = WTARGETINC*7; // PPW

  const SIGMA = 1*0.005248122;
  const MU =  1*-0.000170;
  const DRIFT = MU - SIGMA*SIGMA/2;

  let f_day = Array(user_timeline).fill(0).map((_, i) => i);
  let f_dayleft = Array(user_timeline).fill(0).map((_, i) => user_timeline - i);
  let f_wtarget = Array(user_timeline).fill(0).map((_, i) => user_weight + WTARGETINC*i);
  let f_rv = Array(NUM_SIMS).fill(0).map((_, i) => Array(user_timeline).fill(0).map((_, j) => SIGMA*NormSInv(Math.random())));
  let f_y = Array(NUM_SIMS).fill(0).map((_, i) => Array(user_timeline).fill(0));
  let f_se = Array(NUM_SIMS).fill(0).map((_, i) => Array(user_timeline).fill(0));

  for (let t = 0; t < user_timeline; t++) {
    if (t === 0) { 
      // Set initial values for simulation
      for (const s in f_y) {
        f_y[s][t] = user_weight;
      }
    } else {
      // f_y[t] = f_y[t].map((_, i) => f_y[t][i - 1] * Math.exp(MU + f_rv[t][i]));
      for (const s in f_y) {
        f_y[s][t] = f_y[s][t - 1] * Math.exp(DRIFT + f_rv[s][t]);
        f_se[s][t] = Math.pow(f_y[s][t] - f_wtarget[t],2);
      }
    }
  }

  let f_rmse = f_se.map(s => Math.sqrt(s.reduce((a, b) => a + b, 0)/s.length));

  // const sim_successes = WeightSimulation.f_y.filter(s => Number(s.slice(-1)) <= userGoal);
  // const sim_decents = WeightSimulation.f_y.filter(s => Number(s.slice(-1)) <= userWeight && Number(s.slice(-1)) > userGoal);
  // const sim_failures = WeightSimulation.f_y.filter(s => Number(s.slice(-1)) > userGoal)

  const WeightSimulation = {
    f_day,
    f_wtarget,
    f_y,
    f_y_slim: f_y.slice(0, 25),
    user_target,
    user_weight,
    NUM_SIMS,
    f_rmse,
    u_ppw,
  }

  return WeightSimulation;
}

export default RunWeightSimulation;

// Graciously stolen from here: https://stackoverflow.com/questions/8816729/javascript-equivalent-for-inverse-normal-function-eg-excels-normsinv-or-nor
function NormSInv(p) {
  var a1 = -39.6968302866538, a2 = 220.946098424521, a3 = -275.928510446969;
  var a4 = 138.357751867269, a5 = -30.6647980661472, a6 = 2.50662827745924;
  var b1 = -54.4760987982241, b2 = 161.585836858041, b3 = -155.698979859887;
  var b4 = 66.8013118877197, b5 = -13.2806815528857, c1 = -7.78489400243029E-03;
  var c2 = -0.322396458041136, c3 = -2.40075827716184, c4 = -2.54973253934373;
  var c5 = 4.37466414146497, c6 = 2.93816398269878, d1 = 7.78469570904146E-03;
  var d2 = 0.32246712907004, d3 = 2.445134137143, d4 = 3.75440866190742;
  var p_low = 0.02425, p_high = 1 - p_low;
  var q, r;
  var retVal;

  if ((p < 0) || (p > 1))
  {
      alert("NormSInv: Argument out of range.");
      retVal = 0;
  }
  else if (p < p_low)
  {
      q = Math.sqrt(-2 * Math.log(p));
      retVal = (((((c1 * q + c2) * q + c3) * q + c4) * q + c5) * q + c6) / ((((d1 * q + d2) * q + d3) * q + d4) * q + 1);
  }
  else if (p <= p_high)
  {
      q = p - 0.5;
      r = q * q;
      retVal = (((((a1 * r + a2) * r + a3) * r + a4) * r + a5) * r + a6) * q / (((((b1 * r + b2) * r + b3) * r + b4) * r + b5) * r + 1);
  }
  else
  {
      q = Math.sqrt(-2 * Math.log(1 - p));
      retVal = -(((((c1 * q + c2) * q + c3) * q + c4) * q + c5) * q + c6) / ((((d1 * q + d2) * q + d3) * q + d4) * q + 1);
  }

  return retVal;
}