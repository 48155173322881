import React, { useEffect, useState } from 'react';
import {
  Button,
  Stack,
  Divider,
  Heading,
  VStack,
  Center,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Box,
  Image,
} from '@chakra-ui/react';

import { UseUserContext } from "../../context/UserContext";
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebase/firebase';
import { calcBMI } from '../../bmr/bmr';

interface ChildProps {
  onAdvance: () => void,
  onRetreat: () => void,
}

const OnboardingWeight: React.FC<ChildProps> = (props) => {

  const { currentUser, updateCurrentUserData } = UseUserContext();

  const [isLoading, setIsLoading] = useState(false);

  const startingWeight = currentUser.data.gender === "male" ? 199.8 : 170.8;
  // Source: https://www.cdc.gov/nchs/fastats/body-measurements.htm

  const minUserWeight = 70;
  const maxUserWeight = 500;
  const [userWeightValue, setUserWeightValue] = useState("");

  const handleUserWeightChange = (valueString: string | number) => setUserWeightValue(String(valueString));
  const clampWeight = (valueString: string | number) => Math.max(Math.min(Number(valueString) || startingWeight, maxUserWeight), minUserWeight);
  const clampedUserWeight = clampWeight(userWeightValue);
  const numericUserWeight = Number(userWeightValue);
  const isValidUserWeight = !(!numericUserWeight ||  (numericUserWeight !== clampedUserWeight));

  const userBMIStats = calcBMI(clampedUserWeight, Number(currentUser.data.height_in));
  const validBMI = userBMIStats.bmi >= 17;

  const bmiClass = userBMIStats.bmi < 18.5 ? "blue" : userBMIStats.bmi < 25 ? "green" : userBMIStats.bmi < 30 ? "yellow" :  userBMIStats.bmi < 35 ? "orange" : "red";

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const submitUpdateProfile = async () => {
    if (isValidUserWeight) {
      console.log("Submitting profile update...");
      const profileUpdate = {
        last_weight_lbs: numericUserWeight,
      }
      setIsLoading(true);
      await updateCurrentUserData(currentUser.auth.uid!, profileUpdate);
      console.log("User profile updated...");
      logEvent(analytics, 'profile_updated', {
        uid: currentUser.auth.uid,
        onboard_stage: "onboarded_weight",
      });
      setIsLoading(false);
      props.onAdvance(); // Advance to next step
    }
  }

  return (
    <React.Fragment>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <VStack spacing={8} align="stretch">

          <Heading textAlign={"center"} fontSize={'4xl'}>How much do you currently weigh?</Heading>
          <Text fontSize='2xl' textAlign={"center"}>Every body starts somewhere.</Text>

          <NumberInput 
            min={minUserWeight}
            max={maxUserWeight}
            defaultValue={200}
            precision={1}
            step={1} 
            value={userWeightValue} 
            onChange={handleUserWeightChange}
            clampValueOnBlur={false}
            keepWithinRange={false}

          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>

          <Text fontSize='xl' textAlign={"center"}>If you don't know - you're gonna need a scale! But you can guess for now. We'll update it later.</Text>

          { isValidUserWeight && (
            <React.Fragment>
              <Text fontSize='2xl' textAlign={"center"}>Starting today at {numericUserWeight} lbs</Text>
              <Text textAlign={"center"}>Below is an estimation of your Body Mass Index (BMI) compared to US CDC charts</Text>
              <Slider defaultValue={22} min={15.5} max={40} value={userBMIStats.bmi} isDisabled={true}>
                <SliderMark value={18.5} mt="4" fontSize='xs' fontWeight={"bold"} color="blue.500">
                  18.5
                </SliderMark>
                <SliderMark value={25} mt="4" fontSize='xs' fontWeight={"bold"} color="green.500">
                  25
                </SliderMark>
                <SliderMark value={30} mt="4" fontSize='xs' fontWeight={"bold"} color="yellow.500">
                  30
                </SliderMark>
                <SliderMark value={35} mt="4" fontSize='xs' fontWeight={"bold"} color="orange.500">
                  35
                </SliderMark>
                <SliderTrack>
                  <SliderFilledTrack bg={`${bmiClass}.400`} fontWeight={"bold"}/>
                </SliderTrack>
                <SliderThumb boxSize={6}>
                  {/* <Box color={bmiClass} as={MdArrowDownward} /> */}
                  <Box><Text fontSize={"2xs"} fontWeight="bold" color={`${bmiClass}.500`}>{userBMIStats.bmi}</Text></Box>
                  
                </SliderThumb>
              </Slider>
              <Image src="/assets/images/CDC-BMI-Chart.jpeg" alt="CDC Weight Chart" />
              <Text textAlign={"center"}><i>BMI is a person’s weight in kilograms divided by the square of height in meters. BMI does not measure body fat directly, but is correlated with body fat and metabolic outcomes.</i></Text>
            </React.Fragment>
          )}

          <Divider hidden />

          {(isValidUserWeight && !validBMI) && (
            <Text color={"red.400"}>To ensure safety and accuracy, Basal.ai only supports starting Body Mass Index (BMI) estimates &ge; 17%</Text>
          )}

          <Stack spacing={6} direction={['column', 'row']}>
            {/* <Button
              bg={'red.400'}
              color={'white'}
              w="full"
              _hover={{
                bg: 'red.500',
              }}>
              Cancel
            </Button> */}

            {numericUserWeight && (
              <Button
                bg={'green.400'}
                color={'white'}
                w="full"
                isDisabled={!isValidUserWeight || !validBMI}
                isLoading={isLoading}
                onClick={submitUpdateProfile}
                _hover={{
                  bg: 'green.500',
                }}>
                I'm starting at {numericUserWeight} lbs
              </Button>
            )}
            
          </Stack>

        </VStack>
      </Stack>
    </React.Fragment>
  );
}

export default OnboardingWeight;