import { useState } from 'react';
import {
  Heading,
  Stack,
  useColorModeValue,
  Container,
  Text,
  // Link,
  Button,
} from '@chakra-ui/react';
// import { SmallCloseIcon } from '@chakra-ui/icons';

// import { UseUserContext } from "../context/UserContext";
import ProfileForm from './ProfileForm';
import LogoImage from './LogoImage';
import { createStripeCustomerPortalLink } from '../firebase/firebase';

export default function Profile(): JSX.Element {

  const [isLoading, setIsLoading] = useState(false);
  // const { currentUser, updateCurrentUserData } = UseUserContext();
  // const userName = currentUser.data.name || currentUser.auth.email;
  // const userPlan = "Free Account";

  const redirectToStripeCustomerPortal = async () => {
    setIsLoading(true);
    try {
      await createStripeCustomerPortalLink();
    } catch (e) {
      console.error("Failed to redirectToStripeCustomerPortal");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container maxW={'3xl'}>
      
      {/* <Heading lineHeight={1.5} fontSize={{ base: '5xl', sm: '5xl' }}>
        Profile
      </Heading> */}

      <LogoImage boxSize="xs"/>
      <Heading fontSize={'4xl'}>Update your Profile</Heading>

      <Text fontSize={'lg'} color={'gray.600'}>
        Please make sure this is accurate to allow us to dynamically update your <strong>Basal AI True Weight</strong> estimation with the greatest accuracy possible.
      </Text>

      <Stack
        spacing={4}
        w={'full'}
        maxW={'3xl'}
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={12}>

        <Heading lineHeight={1.1} fontSize={{ base: '2xl', sm: '3xl' }}>
          Your Profile
        </Heading>

        <ProfileForm />

      </Stack>

      <Stack
        spacing={4}
        w={'full'}
        maxW={'3xl'}
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={12}>

        <Heading lineHeight={1.1} fontSize={{ base: '2xl', sm: '3xl' }}>
          Account Billing
        </Heading>

        <Button
          bg={'green.400'}
          color={'white'}
          variant="outline"
          w="full"
          disabled={isLoading}
          isLoading={isLoading}
          onClick={redirectToStripeCustomerPortal}
          _hover={{
            bg: 'green.500',
          }}>
          Billing Settings
        </Button>

      </Stack>
    </Container>
  );
}